import React, { useState, useEffect } from "react";
import ScrollIcon from "../../assets/images/scroller.png";
import "./style.scss";

const Scroller = () => {
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => window.removeEventListener("scroll", handleScroll);
  }, [scrolled]);
  return scrolled ? null : (
    <div className="scrollarrow">
      <div className="scrollarrow__box">
        <div className="scrollarrow__box__arrow">
          <img src={ScrollIcon} width="35px" height="69px" alt="scroll down" />
        </div>
      </div>
    </div>
  );
};

export default Scroller;
