import React from "react";
import { Link } from "react-router-dom";

import TOPIMG from "../../assets/images/ASBUILT_HOME_TOP_IMG.svg";
import BOTTOMIMG from "../../assets/images/ASBUILT_HOME_BOTTOM_IMG.svg";
import LAADPALENICON from "../../assets/images/ASBUILT_LAADPALEN_ICON.svg";
import AIRCOICON from "../../assets/images/ASBUILT_AIRCO_ICON.svg";
import ZONNEPANELENICON from "../../assets/images/ASBUILT_ZONNEPANELEN_ICON.svg";
import EFFICIENTIE from "../../assets/images/efficientie.jpg";
import TRANSPARANTIE from "../../assets/images/transparantie.jpg";
import KLANTGERICHT from "../../assets/images/klantgericht.jpg";
import TextBox from "../TextBox";
import HeroText from "../HeroText";
import "./style.scss";
import Translate from "../../Translate/Translate";
import Scroller from "../Scroller";
import TECHNISCHE from "../../assets/images/technischeevaluaties.jpg";
import FIXES from "../../assets/images/fixes.jpg";

const HomePageHero = () => {
  const currentLocale = Translate.getCurrentLocale().i18n;

  return (
    <div className="asbuilt-homepage-hero">
      <img
        className="asbuilt-homepage-hero__left-img"
        src={TOPIMG}
        alt="Hero Left"
      />
      <div className="asbuilt-homepage-hero__top container">
        <HeroText title={Translate.translate("homeTitle")} />
        <div
          className="asbuilt-homepage-hero__top__buttons"
          data-aos="fade-up"
          data-aos-delay="500"
        >
          <Link
            to={`/${currentLocale}/${Translate.translate("linkLaadpalen")}`}
            className="asbuilt-homepage-hero__top__buttons__item asbuilt-homepage-hero__top__buttons__item--left"
          >
            {Translate.translate("homeDiscover")}
          </Link>
          <Link
            to={`/${currentLocale}/${Translate.translate("linkContact")}`}
            className="asbuilt-homepage-hero__top__buttons__item asbuilt-homepage-hero__top__buttons__item--right"
          >
            {Translate.translate("homePartner")}
          </Link>
        </div>

        <div className="asbuilt-homepage-hero__top__focus">
          <div className="asbuilt-homepage-hero__top__focus__item">
            <img src={EFFICIENTIE} alt="efficientie" width="98" height="98" />
            <span>{Translate.translate("homeEfficiency")}</span>
          </div>
          <div className="asbuilt-homepage-hero__top__focus__item">
            <img
              src={TRANSPARANTIE}
              alt="transparantie"
              width="98"
              height="98"
            />
            <span>{Translate.translate("homeTransparancy")}</span>
          </div>
          <div className="asbuilt-homepage-hero__top__focus__item">
            <img src={KLANTGERICHT} alt="klantgericht" width="98" height="98" />
            <span>{Translate.translate("homeCustomer")}</span>
          </div>
        </div>
        <Scroller />
      </div>
      <div className="asbuilt-homepage-hero__bottom container">
        <div
          className="asbuilt-homepage-hero__bottom__text"
          data-aos="fade-right"
        >
          <TextBox
            title={Translate.translate("homeGoal")}
            desc={Translate.translate("homeGoalText")}
          />
          <div>
            <div className="asbuilt-homepage-hero__bottom__focus">
              <div className="asbuilt-homepage-hero__bottom__focus__item">
                <img
                  src={TECHNISCHE}
                  alt="technische evaluatie"
                  width="98"
                  height="98"
                />
                <span>{Translate.translate("homeTechnicalEvaluations")}</span>
              </div>
              <div className="asbuilt-homepage-hero__bottom__focus__item">
                <img src={FIXES} alt="fixes" width="98" height="98" />
                <span>{Translate.translate("homeFixes")}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <img
        className="asbuilt-homepage-hero__right-img"
        src={BOTTOMIMG}
        alt="Hero Right"
      />
    </div>
  );
};

export default HomePageHero;
