import React from "react";

import TextBox from "../TextBox";
import "./style.scss";
import Translate from "../../Translate/Translate";

const ContentItemNew = ({ title, desc, img, orientation }) => {
  return (
    <div
      className={`asbuilt-content-item ${
        orientation === "left" ? "asbuilt-content-item--left" : null
      }`}
    >
      <div
        className="asbuilt-content-item__left"
        data-aos={orientation === "left" ? "fade-left" : "fade-right"}
      >
        <TextBox
          title={Translate.translate(title)}
          desc={Translate.translate(desc)}
        />
      </div>
      <div
        className="asbuilt-content-item__right"
        data-aos={orientation === "right" ? "fade-left" : "fade-right"}
      >
        <img className="asbuilt-content-item__img" src={img} alt="Home Item" />
      </div>
    </div>
  );
};

export default ContentItemNew;
