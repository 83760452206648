import React from 'react';
import ContactSection from '../components/ContactSection';
import TranslatedComponent from './TranslatedComponent';
import Header from '../components/Header';
import CarousselSection from '../components/CarousselSection';
import TechnicalHero from '../components/TechnicalHero';
import ContentItemNew from '../components/ContentItemNew';
import HOMEITEM1 from '../assets/images/ASBUILT_FIX1.jpg';
import HOMEITEM2 from '../assets/images/ASBUILT_FIX2.jpg';

const data = [
  {
    id: 1,
    title: 'fixesBlockTitle1',
    desc: 'fixesBlockText1',
    img: HOMEITEM1,
    orientation: 'right',
  },
  {
    id: 2,
    title: 'fixesBlockTitle2',
    desc: 'fixesBlockText2',
    img: HOMEITEM2,
    orientation: 'left',
  },
];

const Herstellingen = (props) => {
  return (
    <div>
      <TranslatedComponent {...props}>
        <Header {...props} />
        <TechnicalHero
          title="fixesTitle"
          button="becomePartner"
          buttonLink="linkContact"
          specials={true}
        />
        <div
          style={{ width: '100vw', display: 'flex', justifyContent: 'center' }}
        >
          <div style={{ marginTop: 60, width: '100%', maxWidth: 860 }}>
            <video controls width="100%" height="520" autoPlay={true}>
              <source
                src="/videos/sungevity_testimonial.mp4"
                type="video/mp4"
              />
              Sorry, your browser doesn't support embedded videos.
            </video>
          </div>
        </div>
        <div className="container" style={{ marginTop: 60, marginBottom: 100 }}>
          {data.map((item) => (
            <ContentItemNew key={item.id} {...item} />
          ))}
        </div>

        <CarousselSection />
        <ContactSection />
      </TranslatedComponent>
    </div>
  );
};

export default Herstellingen;
