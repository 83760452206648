import React from "react";
import { Link } from "react-router-dom";
import Translate from "../../Translate/Translate";

import "./style.scss";
import LOGO from "../../assets/images/ASBUILT_LOGO_FOOTER.svg";

const Footer = () => {
  return (
    <div className="asbuilt-footer">
      <div className="asbuilt-footer__container container">
        <ul className="asbuilt-footer__menu">
          <li className="asbuilt-footer__menu__item">
            <span className="asbuilt-footer__menu__item__title">
              {Translate.translate("address")}
            </span>
            <br />
            Nijverheidslaan 7
            <br />
            1853 Strombeek-Bever
          </li>
          <li className="asbuilt-footer__menu__item">
            <span className="asbuilt-footer__menu__item__title">
              {Translate.translate("email")}
            </span>
            <br />
            <a
              href="mailto:info@asbuilt.be"
              className="asbuilt-footer__menu__item__link"
            >
              info@asbuilt.be
            </a>
          </li>
          <li className="asbuilt-footer__menu__item">
            <span className="asbuilt-footer__menu__item__title">
              {Translate.translate("tel")}
            </span>
            <br />
            <a
              href="tel:003226696565"
              className="asbuilt-footer__menu__item__link"
            >
              +32 2 669 65 65
            </a>
          </li>
        </ul>
        <Link className="asbuilt-footer__logo" to="/">
          <img src={LOGO} alt="Footer Logo" />
        </Link>
      </div>
      <div className="asbuilt-footer__container container">
        <a
          href="/algemenevoorwaarden.pdf"
          className="asbuilt-footer__menu__item__link"
        >
          {Translate.translate("terms")}
        </a>
      </div>
    </div>
  );
};

export default Footer;
