import Caroussel from "../Caroussel";
import Translate from "../../Translate/Translate";
import CARIMG1 from "../../assets/images/home_car_1.jpg";
import CARIMG2 from "../../assets/images/home_car_2.jpg";
import CARIMG3 from "../../assets/images/home_car_3.jpg";
import CARIMG4 from "../../assets/images/home_car_4.jpg";
import CARIMG5 from "../../assets/images/home_car_5.jpg";
import "./style.scss";

const CarousselSection = () => {
  const carousselItems = [
    {
      image: CARIMG1,
      title: Translate.translate("carousselTitle1"),
      text: Translate.translate("carousselText1"),
    },
    {
      image: CARIMG2,
      title: Translate.translate("carousselTitle2"),
      text: Translate.translate("carousselText2"),
    },
    {
      image: CARIMG3,
      title: Translate.translate("carousselTitle3"),
      text: Translate.translate("carousselText3"),
    },
    {
      image: CARIMG4,
      title: Translate.translate("carousselTitle4"),
      text: Translate.translate("carousselText4"),
    },
    {
      image: CARIMG5,
      title: Translate.translate("carousselTitle5"),
      text: Translate.translate("carousselText5"),
    },
  ];

  return (
    <div className="carousselsection_root">
      <div className="carousselsection_root__inner">
        <Caroussel items={carousselItems} />
      </div>
    </div>
  );
};

export default CarousselSection;
