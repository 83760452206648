import { Link } from "react-router-dom";
import Translate from "../../Translate/Translate";
import "./style.scss";
import LAADPALENICON from "../../assets/images/ASBUILT_LAADPALEN_ICON.svg";
import AIRCOICON from "../../assets/images/ASBUILT_AIRCO_ICON.svg";
import ZONNEPANELENICON from "../../assets/images/ASBUILT_ZONNEPANELEN_ICON.svg";
import EFFICIENTIE from "../../assets/images/efficientie.png";
import TRANSPARANTIE from "../../assets/images/transparantie.png";
import KLANTGERICHT from "../../assets/images/klantgericht.png";

const TechnicalHero = ({ title, text, button, buttonLink, specials }) => {
  const currentLocale = Translate.getCurrentLocale().i18n;

  return (
    <div className="technical_root">
      <div className="technical_root__inner">
        <div>
          <h1 className="technical_root__inner__title">
            {Translate.translate(title)}
          </h1>
        </div>
        {text && (
          <div>
            <p className="technical_root__inner__text">
              {Translate.translate(text)}
            </p>
          </div>
        )}
        <Link
          to={`/${currentLocale}/${Translate.translate(buttonLink)}`}
          className="technical_root__inner__button"
        >
          {Translate.translate(button)}
        </Link>
        {!specials ? (
          <div className="technical_root__inner__icons">
            <Link
              to={`/${currentLocale}/${Translate.translate("linkLaadpalen")}`}
              className="technical_root__inner__icons__icon"
              data-aos="fade-down"
            >
              <img
                className="technical_root__inner__icons__icon__img"
                src={LAADPALENICON}
                alt="Laadpalenicon"
              />
              <div className="technical_root__inner__icons__icon__text">
                {Translate.translate("homeLaadpalen")}
              </div>
            </Link>
            <Link
              to={`/${currentLocale}/${Translate.translate(
                "linkZonnepanelen",
              )}`}
              className="technical_root__inner__icons__icon"
              data-aos-delay="200"
              data-aos="fade-down"
            >
              <img
                className="technical_root__inner__icons__icon__img"
                src={ZONNEPANELENICON}
                alt="Aonnepanelenicon"
              />
              <div className="technical_root__inner__icons__icon__text">
                {Translate.translate("homeZonnepanelen")}
              </div>
            </Link>
            <Link
              to={`/${currentLocale}/${Translate.translate("linkAirco")}`}
              className="technical_root__inner__icons__icon"
              data-aos-delay="400"
              data-aos="fade-down"
            >
              <img
                className="technical_root__inner__icons__icon__img"
                src={AIRCOICON}
                alt="Aircoicon"
              />
              <div className="technical_root__inner__icons__icon__text">
                {Translate.translate("homeAirco")}
              </div>
            </Link>
          </div>
        ) : (
          <div className="technical_root__inner__icons">
            <div
              className="technical_root__inner__icons__icon"
              data-aos="fade-down"
            >
              <img
                className="technical_root__inner__icons__icon__img"
                src={EFFICIENTIE}
                alt="Laadpalenicon"
              />
              <div className="technical_root__inner__icons__icon__text">
                {Translate.translate("homeEfficiency")}
              </div>
            </div>
            <div
              className="technical_root__inner__icons__icon"
              data-aos-delay="200"
              data-aos="fade-down"
            >
              <img
                className="technical_root__inner__icons__icon__img"
                src={TRANSPARANTIE}
                alt="Aonnepanelenicon"
              />
              <div className="technical_root__inner__icons__icon__text">
                {Translate.translate("homeTransparancy")}
              </div>
            </div>
            <div
              className="technical_root__inner__icons__icon"
              data-aos-delay="400"
              data-aos="fade-down"
            >
              <img
                className="technical_root__inner__icons__icon__img"
                src={KLANTGERICHT}
                alt="Aircoicon"
              />
              <div className="technical_root__inner__icons__icon__text">
                {Translate.translate("homeCustomer")}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TechnicalHero;
