import React from "react";

import "./style.scss";

const TextBox = ({ title, desc }) => {
  return (
    <div className="asbuilt-textbox">
      <div className="asbuilt-textbox__title">{title}</div>
      <div className="asbuilt-textbox__desc">{desc}</div>
    </div>
  );
};

export default TextBox;
