import React from "react";
import TranslatedComponent from "./TranslatedComponent";
import Header from "../components/Header";
import BarHero from "../components/BarHero";
import Translate from "../Translate/Translate";
import JobsNL from "../data/JobsNL";
import JobsFR from "../data/JobsFR";
import JobDetail from "../components/JobDetail";

const data = {
  subbarTitle: "jobsSubbarTitle",
  subbarText: "jobsSubbarText",
};

const JobsDetailPage = (props) => {
  const { match } = props;
  const currentLocale = Translate.getCurrentLocale().i18n;
  const jobURL = (match && match.params && match.params.jobslug) || "";
  let jobs;
  if (currentLocale === "nl") {
    jobs = JobsNL;
  }
  if (currentLocale === "fr") {
    jobs = JobsFR;
  }
  let currentJob;
  if (jobs && jobURL) {
    for (let j = 0; j < jobs.length; j++) {
      if (jobs[j].url === jobURL) {
        currentJob = jobs[j];
        break;
      }
    }
  }

  return (
    <div>
      <TranslatedComponent {...props}>
        <Header {...props} />
        <BarHero title="JOBS" />
        {currentJob && <JobDetail job={currentJob} />}
      </TranslatedComponent>
    </div>
  );
};

export default JobsDetailPage;
