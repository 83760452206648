import "./style.scss";
import Translate from "../../Translate/Translate";

const SubBar = ({ title, text }) => {
  return (
    <div className="subbar-root">
      <div className="subbar-root__inner">
        <h2>{Translate.translate(title)}</h2>
        <p>{Translate.translate(text)}</p>
      </div>
    </div>
  );
};

export default SubBar;
