import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'bootstrap/dist/css/bootstrap.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Translate from './Translate/Translate';
import i18n from './config/i18n';

Translate.setLocales(i18n.createLocales());
if (!Translate.getCurrentLocale() || !Translate.getCurrentLocale().i18n) {
  Translate.setLocale('nl');
}

AOS.init({ duration: 1000 });

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
