import React from "react";
import LAADPALENICON from "../assets/images/ASBUILT_LAADPALEN_ICON.svg";
import LAADPALENITEM1 from "../assets/images/ASBUILT_LAAD1.jpg";
import LAADPALENITEM2 from "../assets/images/ASBUILT_LAAD2.jpg";
import LAADPALENITEM3 from "../assets/images/ASBUILT_LAAD3.jpg";
import ContactSection from "../components/ContactSection";
import TranslatedComponent from "./TranslatedComponent";
import Header from "../components/Header";
import BarHero from "../components/BarHero";
import TechnicalHero from "../components/TechnicalHero";
import ContentItemNew from "../components/ContentItemNew";
import CarousselSection from "../components/CarousselSection";

const data = [
  {
    id: 1,
    title: "laadpalenBlockTitle1",
    desc: "laadpalenBlockText1",
    img: LAADPALENITEM1,
    orientation: "right",
  },
  {
    id: 2,
    title: "laadpalenBlockTitle2",
    desc: "laadpalenBlockText2",
    img: LAADPALENITEM2,
    orientation: "left",
  },
  {
    id: 3,
    title: "laadpalenBlockTitle3",
    desc: "laadpalenBlockText3",
    img: LAADPALENITEM3,
    orientation: "right",
  },
];

const discoverTitle = "laadpalenDiscoverTitle";

const discoverData = [
  {
    id: 1,
    data: "laadpalenDiscoverBlockText1",
  },
  {
    id: 2,
    data: "laadpalenDiscoverBlockText2",
  },
  {
    id: 3,
    data: "laadpalenDiscoverBlockText3",
  },
  {
    id: 4,
    data: "laadpalenDiscoverBlockText4",
  },
  {
    id: 5,
    data: "laadpalenDiscoverBlockText5",
  },
];

const LaadpalenPage = (props) => {
  return (
    <div>
      <TranslatedComponent {...props}>
        <Header {...props} />
        <BarHero barIcon={LAADPALENICON} />
        <TechnicalHero
          title="laadpalenTitle"
          text="laadpalenSubtitle"
          button="becomePartner"
          buttonLink="linkContact"
          specials={true}
        />
        <div className="container" style={{ marginTop: 60, marginBottom: 100 }}>
          {data.map((item) => (
            <ContentItemNew key={item.id} {...item} />
          ))}
        </div>
        <CarousselSection />
        <ContactSection />
      </TranslatedComponent>
    </div>
  );
};

export default LaadpalenPage;
