import { useEffect, useState } from "react";
import ARROWLEFT from "../../assets/images/arrowleft.png";
import ARROWRIGHT from "../../assets/images/arrowright.png";
import "./style.scss";

const Caroussel = ({ items }) => {
  const [sliding, setSliding] = useState(false);
  const [slideCount, setSlideCount] = useState(0);
  const [touchStartX, setTouchStartX] = useState(0);
  const [touchMoveX, setTouchMoveX] = useState(0);
  const [itemWidth, setItemWidth] = useState(0);

  const setItemWidthOnResize = () => {
    const item = document.getElementById("carousselitem");
    const itemWidth = item.offsetWidth;
    console.log(itemWidth);
    setItemWidth(itemWidth);
  };

  const handleResize = () => {
    setItemWidthOnResize();
  };

  useEffect(() => {
    setItemWidthOnResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, [itemWidth]);

  const slideItemsUp = () => {
    setSliding(true);
    setSlideCount(slideCount + 1);
  };

  const slideItemsDown = () => {
    setSliding(true);
    setSlideCount(slideCount - 1);
  };

  const handleTouchStart = (touchStartEvent) => {
    setTouchStartX(touchStartEvent.targetTouches[0].clientX);
    setTouchMoveX(0);
  };

  const handleTouchMove = (touchMoveEvent) => {
    setTouchMoveX(touchMoveEvent.targetTouches[0].clientX);
  };

  const handleTouchEnd = () => {
    const startX = touchStartX;
    const endX = touchMoveX;
    setTouchStartX(0);
    setTouchMoveX(0);
    if (endX < startX) {
      slideItemsDown();
    } else {
      slideItemsUp();
    }
  };

  return (
    <div className="caroussel_root">
      <div className="caroussel_root__top">
        <div className="caroussel_root__top__title">
          Ontdek hoe Asbuilt werkt:
        </div>
        <div className="caroussel_root__top__arrows">
          <img
            src={ARROWLEFT}
            alt="arrow left"
            width="50px"
            height="50px"
            onClick={() => (slideCount > 0 ? slideItemsDown() : null)}
            style={{ opacity: slideCount > 0 ? 1 : 0.3 }}
          />
          <img
            src={ARROWRIGHT}
            alt="arrow right"
            width="50px"
            height="50px"
            onClick={() =>
              slideCount < items.length - 1 ? slideItemsUp() : null
            }
            style={{ opacity: slideCount < items.length - 1 ? 1 : 0.3 }}
          />
        </div>
      </div>
      <div className="caroussel_root__items">
        {items &&
          items.length &&
          items.map((item, i) => {
            const fullWidth = itemWidth + 60;
            const itemLeft = i * fullWidth - slideCount * fullWidth;
            return (
              <div
                key={i}
                className="caroussel_root__items__item"
                id="carousselitem"
                style={{ left: itemLeft, width: itemWidth }}
              >
                <div className="caroussel_root__items__item__img">
                  <img
                    src={item.image}
                    alt="asbuilt"
                    width="100%"
                    height="auto"
                  />
                </div>
                <div className="caroussel_root__items__item__textbox">
                  <p className="caroussel_root__items__item__textbox__title">
                    <span>{i + 1}.</span> {item.title}
                  </p>
                  <p className="caroussel_root__items__item__textbox__text">
                    {item.text}
                  </p>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default Caroussel;
