import React from "react";

import "./style.scss";

const HeroText = ({ title, desc }) => {
  return (
    <div className="asbuilt-herotext" data-aos="fade-up">
      <div className="asbuilt-herotext__title">{title}</div>
      {desc && <div className="asbuilt-herotext__desc">{desc}</div>}
    </div>
  );
};

export default HeroText;
