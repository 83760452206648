import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import Footer from './components/Footer';
import HomePage from './pages/HomePage';
import LaadpalenPage from './pages/LaadpalenPage';
import AircoPage from './pages/AircoPage';
import ZonnepanelenPage from './pages/ZonnepanelenPage';
import ContactSection from './components/ContactSection';
import ScrollToTop from './components/ScrollToTop';
import LanguageRoute from './LanguageRoute';
import TechnischeEvaluaties from './pages/TechnischeEvaluaties';
import Herstellingen from './pages/Herstellingen';
import JobsPage from './pages/JobsPage';
import JobsDetailPage from './pages/JobsDetailPage';

function App() {
  return (
    <Router>
      <ScrollToTop />
      <Switch>
        <Route
          exact
          path={'/nl/laadpalen'}
          render={(props) => <LaadpalenPage {...props} />}
        />
        <Route
          exact
          path={'/nl/technische-evaluaties'}
          render={(props) => <TechnischeEvaluaties {...props} />}
        />
        <Route
          exact
          path={'/nl/herstellingen'}
          render={(props) => <Herstellingen {...props} />}
        />
        <Route
          exact
          path={'/nl/zonnepanelen'}
          render={(props) => <ZonnepanelenPage {...props} />}
        />
        <Route
          exact
          path={'/nl/airco'}
          render={(props) => <AircoPage {...props} />}
        />
        <Route
          exact
          path={'/nl/contacteer-ons'}
          render={(props) => <ContactSection page={true} {...props} />}
        />
        <Route
          exact
          path={'/nl/jobs'}
          render={(props) => <JobsPage {...props} />}
        />
        <Route
          exact
          path={'/fr/jobs'}
          render={(props) => <JobsPage {...props} />}
        />
        <Route
          exact
          path={'/fr/jobs/:jobslug'}
          render={(props) => <JobsDetailPage {...props} />}
        />
        <Route
          exact
          path={'/nl/jobs/:jobslug'}
          render={(props) => <JobsDetailPage {...props} />}
        />
        <Route
          exact
          path={'/fr/evaluations-techniques'}
          render={(props) => <TechnischeEvaluaties {...props} />}
        />
        <Route
          exact
          path={'/fr/reparations'}
          render={(props) => <Herstellingen {...props} />}
        />
        <Route
          exact
          path={'/fr/bornes-de-recharge'}
          render={(props) => <LaadpalenPage {...props} />}
        />
        <Route
          exact
          path={'/fr/panneaux-solaires'}
          render={(props) => <ZonnepanelenPage {...props} />}
        />
        <Route
          exact
          path={'/fr/climatiseurs'}
          render={(props) => <AircoPage {...props} />}
        />
        <Route
          exact
          path={'/fr/contactez-nous'}
          render={(props) => <ContactSection page={true} {...props} />}
        />
        <Route
          exact
          path={'/en/technical-evaluations'}
          render={(props) => <TechnischeEvaluaties {...props} />}
        />
        <Route
          exact
          path={'/en/repairs'}
          render={(props) => <Herstellingen {...props} />}
        />
        <Route
          exact
          path={'/en/charging-stations'}
          render={(props) => <LaadpalenPage {...props} />}
        />
        <Route
          exact
          path={'/en/solar-panels'}
          render={(props) => <ZonnepanelenPage {...props} />}
        />
        <Route
          exact
          path={'/en/air-conditioning'}
          render={(props) => <AircoPage {...props} />}
        />
        <Route
          exact
          path={'/en/contact-us'}
          render={(props) => <ContactSection page={true} {...props} />}
        />
        <Route
          exact
          path={`/:language`}
          render={(props) => <HomePage {...props} />}
        />
        <LanguageRoute path="/" component={HomePage} />
      </Switch>
      <Footer />
    </Router>
  );
}

export default App;
