import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const LanguageRoute = ({ component: Component, ...rest }) => {
  let language = undefined;
  return (
    <Route
      {...rest}
      render={(props) =>
        language ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/nl',
            }}
          />
        )
      }
    />
  );
};

export default LanguageRoute;
