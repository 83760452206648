class Locale {
  i18n = null;
  language = null;
  iso = null;
  translations = null;

  constructor(i18n = "", language = "", iso = "", translations = []) {
    this.i18n = i18n;
    this.language = language;
    this.iso = iso;
    this.translations = translations;
  }
}

class Translate {
  constructor() {
    this._currentI18n = null;
    this._currentLocale = null;
    this._locales = null;
  }

  translate(key = "") {
    try {
      if (this._currentLocale) {
        return this._currentLocale.translations[key] || key;
      } else {
        return key;
      }
    } catch {
      return "";
    }
  }

  createLocale(i18n, language, iso, translations) {
    return new Locale(i18n, language, iso, translations);
  }

  setI18n(i18n) {
    this._currentI18n = i18n;
  }

  setLocales(locales = null) {
    if (locales && locales.length) {
      this._locales = locales;
      if (this._currentI18n) {
        this.setLocale(this._currentI18n);
      }
    } else {
      throw new Error(
        "Please provide array of Locales -> translate.createLocale",
      );
    }
  }

  getLocales() {
    console.log(this._locales);
    return this._locales || null;
  }

  getCurrentLocale() {
    return this._currentLocale;
  }

  getLocaleSwitch(language) {
    let locs = [];
    for (var l = 0; l < this._locales.length; l++) {
      locs.push({
        value: language ? this._locales[l].language : this._locales[l].iso,
        i18n: this._locales[l].i18n,
        current: this._currentLocale
          ? this._locales[l].i18n === this._currentLocale.i18n
          : false,
      });
    }
    return locs;
  }

  setLocale(i18n = "") {
    if (this._locales && this._locales.length) {
      for (var l = 0; l < this._locales.length; l++) {
        if (this._locales[l].i18n === i18n) {
          this._currentLocale = this._locales[l];
        }
      }
    } else {
      throw new Error("Locales not set");
    }
  }
}

const translate = new Translate();

export default translate;
