import React, { Component } from "react";
import Translate from "../Translate/Translate";

class TranslatedComponent extends Component {
  componentDidMount = () => {
    if (
      this.props.match &&
      this.props.match &&
      this.props.match.params &&
      this.props.match.params.language
    ) {
      const { language } = this.props.match.params;
      if (language === "nl" || language === "fr" || language === "en") {
        if (language !== Translate.getCurrentLocale().i18n) {
          Translate.setLocale(language);
          Translate.setI18n(language);
          this.props.history.push(this.props.location.pathname);
        }
      }
    }
  };

  render() {
    const currentLocale =
      (this.props.match &&
        this.props.match &&
        this.props.match.params &&
        this.props.match.params.language) ||
      undefined;
    return <div key={currentLocale}>{this.props.children}</div>;
  }
}

export default TranslatedComponent;
