import React from "react";
import AIRCOICON from "../assets/images/ASBUILT_AIRCO_ICON.svg";
import AIRCOITEM1 from "../assets/images/ASBUILT_AIRCO1.jpg";
import AIRCOITEM2 from "../assets/images/ASBUILT_AIRCO2.jpg";
import ContactSection from "../components/ContactSection";
import TranslatedComponent from "./TranslatedComponent";
import Header from "../components/Header";
import BarHero from "../components/BarHero";
import TechnicalHero from "../components/TechnicalHero";
import ContentItemNew from "../components/ContentItemNew";
import CarousselSection from "../components/CarousselSection";

const data = [
  {
    id: 1,
    title: "aircoBlockTitle1",
    desc: "aircoBlockText1",
    img: AIRCOITEM1,
    orientation: "right",
  },
  {
    id: 2,
    title: "aircoBlockTitle2",
    desc: "aircoBlockText2",
    img: AIRCOITEM2,
    orientation: "left",
  },
];

const discoverTitle = "aircoDiscoverTitle";

const discoverData = [
  {
    id: 1,
    data: "aircoDiscoverBlockText1",
  },
  {
    id: 2,
    data: "aircoDiscoverBlockText2",
  },
  {
    id: 3,
    data: "aircoDiscoverBlockText3",
  },
];

const AircoPage = (props) => {
  return (
    <div>
      <TranslatedComponent {...props}>
        <Header {...props} />
        <BarHero barIcon={AIRCOICON} />
        <TechnicalHero
          title="aircoTitle"
          text="aircoSubtitle"
          button="becomePartner"
          buttonLink="linkContact"
          specials={true}
        />
        <div className="container" style={{ marginTop: 60, marginBottom: 100 }}>
          {data.map((item) => (
            <ContentItemNew key={item.id} {...item} />
          ))}
        </div>
        <CarousselSection />
        <ContactSection />
      </TranslatedComponent>
    </div>
  );
};

export default AircoPage;
