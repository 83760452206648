import React from "react";
import "./style.scss";

const BarHero = ({ barIcon, title }) => {
  return (
    <div className="asbuilt-barhero-hero">
      <div className="asbuilt-barhero-hero__bar">
        {barIcon && (
          <img
            className="asbuilt-barhero-hero__bar__icon"
            src={barIcon}
            alt="Bar Icon"
          />
        )}
        {title && <h1>{title}</h1>}
      </div>
    </div>
  );
};

export default BarHero;
