const translations = {
  homeTitle:
    "Nous aidons les installateurs et les entrepreneurs à obtenir leurs attestations et certificats",
  homeDiscover: "DÉCOUVREZ COMMENT NOUS PROCÉDONS",
  homePartner: "DEVENEZ PARTENAIRE D'ASBUILT",
  homeContact: "CONTACTEZ-NOUS",
  homeGoal: "Une solution ciblée pour les installateurs et les entrepreneurs.",
  homeEfficiency: "AUGMENTEZ VOTRE EFFICACITÉ",
  homeTransparancy: "100 % TRANSPARENT",
  homeCustomer: "ORIENTÉ CLIENT",
  homeTechnicalEvaluations: "ÉVALUATIONS TECHNIQUES",
  homeFixes: "RÉPARATIONS",
  linkTechnicalEvaluations: "evaluations-techniques",
  linkFixes: "reparations",
  carousselTitle1: "MESURE ET INSPECTION",
  carousselText1: (
    <>
      Les experts d'AsBuilt se rendent sur place et établissent un rapport de
      mesure et d’inspection détaillé. Vous recevez ensuite nos conclusions et
      nos conseils dans un rapport numérique incluant des photos et des schémas.
    </>
  ),
  carousselTitle2: "INSTALLATION",
  carousselText2: (
    <>
      Vous exécutez l’installation / le projet sur la base du rapport numérique.
    </>
  ),
  carousselTitle3: "ASSISTANCE",
  carousselText3: (
    <>
      Pendant l’installation, les experts d'AsBuilt sont toujours à disposition
      pour exécuter des tâches complexes telles que les branchements et les
      configurations.
    </>
  ),
  carousselTitle4: "CONTRÔLE",
  carousselText4: (
    <>Une fois l’installation réalisée, nous demandons le contrôle.</>
  ),
  carousselTitle5: "CORRECTION RAPIDE",
  carousselText5: (
    <>
      L’installation a été refusée ? Nous remédions aux problèmes et nous
      demandons un nouveau contrôle.
    </>
  ),
  homeGoalText: (
    <>
      Augmentez l’efficacité de votre entreprise. Gagnez plus de temps. Veillez
      à la satisfaction de vos clients. L’ambition d’AsBuilt est de vous
      simplifier la vie autant que possible et de faire en sorte que vos clients
      soient toujours satisfaits. Confiez-nous l’exécution de vos installations
      et projets.
    </>
  ),
  homeLaadpalen: "BORNES DE RECHARGE",
  homeZonnepanelen: "PANNEAUX SOLAIRES",
  homeAirco: "CLIMATISEURS",
  homeBlockTitle1: "AsBuilt renforce votre efficacité",
  homeBlockText1:
    "Avant que vous ne réalisiez une installation chez un client, AsBuilt se rend sur place afin de tout mesurer correctement. Vous pouvez ainsi travailler plus rapidement et de manière plus ciblée. En outre, le risque d'un problème par la suite est inexistant.",
  homeBlockTitle2: "100 % transparent",
  homeBlockText2:
    "Grâce à notre plateforme numérique, vous disposez toujours d'un aperçu de la situation actuelle. Vous pouvez ainsi suivre tous vos projets, où que vous soyez et quand vous le souhaitez. ",
  homeBlockTitle3: "Une défectuosité ? Nous réglons le problème !",
  homeBlockText3: (
    <>
      Chez AsBuilt, nous visons une satisfaction client de 100 %. Notre offre de
      services est orientée solutions ! Des problèmes inattendus ? Des
      défectuosités ? <strong>Nous venons immédiatement les résoudre</strong>
    </>
  ),
  homeDiscoverTitle: "Découvrez ci-dessous la plus-value d’AsBuilt…",
  homeDiscoverBlockText1: (
    <>
      <u>Étape 1 : relevé des mesures et inspection</u>
      <br />
      Nos collaborateurs spécialisés se rendent chez votre client et établissent
      un rapport de mesure et d’inspection détaillé. Vous recevez nos
      conclusions et nos conseils dans un rapport numérique incluant des photos
      et des schémas !
    </>
  ),
  homeDiscoverBlockText2: (
    <>
      <u>Étape 2 : installation</u>
      <br />
      Vous exécutez l’installation/le projet sur la base de notre rapport
      numérique.
    </>
  ),
  homeDiscoverBlockText3: (
    <>
      <u>Étape 3 : notre assistance</u>
      <br />
      Les tâches complexes du processus d’installation (branchements,
      configurations...) peuvent être exécutées à votre demande par nos
      techniciens. Cela vous laisse plus de temps et d'espace pour vous
      concentrer sur les aspects importants de votre activité.
    </>
  ),
  homeDiscoverBlockText4: (
    <>
      <u>Étape 4 : contrôle</u>
      <br />
      L’installation est terminée ? Nous en demandons le contrôle !
    </>
  ),
  homeDiscoverBlockText5: (
    <>
      <u>Étape 5 : le contrôle se solde par un rejet ? Des défectuosités ?</u>
      <br />
      Des problèmes ou un refus ? Des défectuosités ? AsBuilt se rend sur place
      et résout le problème !
    </>
  ),
  contactTop: "DEVENEZ PARTENAIRE D’ASBUILT ET",
  contact: (
    <>
      RECEVEZ VOTRE PREMIER
      <br />
      RELEVÉ DE MESURES GRATUIT
    </>
  ),
  contactText: (
    <>
      Contactez-nous et nous examinerons la manière dont nous pouvons vous
      aider.
    </>
  ),
  technicalTitle: "Évaluations techniques",
  becomePartner: "DEVENEZ PARTENAIRE D'ASBUILT",
  thanks: "Merci",
  thanksText:
    "Nous répondrons à votre question aussi rapidement que possible !",
  name: "NOM",
  email: "E-MAIL",
  tel: "TÉLÉPHONE",
  message: "VOTRE MESSAGE",
  contactUs: "CONTACTEZ-NOUS",
  terms: "Conditions générales",
  address: "ADRESSE",
  laadpalenTitle: "Installation de bornes de recharge",
  laadpalenSubtitle: "Mesures pour un processus d’installation impeccable",
  efficiency: "AUGMENTEZ VOTRE EFFICACITÉ",
  transparancy: "100 % TRANSPARENT",
  customer: "ORIENTÉ CLIENT",
  laadpalenBlockTitle1: "Évaluation technique",
  laadpalenBlockText1: (
    <>
      Dans un monde idéal, installer vos bornes de recharge devrait être aussi
      simple partout, mais ce n’est pas toujours le cas. Avant de pouvoir
      installer les bornes de recharge, un relevé précis des mesures du lieu est
      un atout énorme.
      <br />
      <br />
      Un mesurage correct du site vous permet d'éviter les mauvaises expériences
      pour votre client final. Votre installation peut être réalisée sans
      problème et vous ne rencontrerez pas de difficultés imprévues.
    </>
  ),
  laadpalenBlockTitle2: "Un gain de temps considérable",
  laadpalenBlockText2: (
    <>
      Quelque chose ne va pas après l’installation ? Ou quelque chose n’a pas
      été approuvé lors de l’inspection de votre installation ? Ne perdez plus
      de temps à retourner chez les clients. Une pièce est défectueuse après
      l'installation de votre borne de recharge ? AsBuilt vient la réparer.
    </>
  ),
  laadpalenBlockTitle3: "Une solution globale inédite",
  laadpalenBlockText3: (
    <>
      En étroite collaboration avec nos partenaires, nous proposons à nos
      clients une solution globale inédite. Qu'est-ce que cela signifie ?
      <br />
      <br />
      Nous veillons à ce que votre installation puisse être réalisée sans
      problème et soit prête à être certifiée.
      <br />
      <br />
      En cas de problème entraînant un refus lors du contrôle, nous revenons
      pour y remédier. Quoi qu'il arrive, chez nous, vous êtes entre de bonnes
      mains.
    </>
  ),
  laadpalenDiscoverTitle:
    "Voyez ci-dessous comment AsBuilt augmente votre efficacité !",
  laadpalenDiscoverBlockText1: (
    <>
      <strong>1. Étape 1 : le relevé des mesures</strong>
      <br />
      Vous vous connectez à notre plateforme numérique et confirmez la demande.
      <br />
      Un releveur spécialisé se rend TRÈS RAPIDEMENT sur place et vous remet un
      rapport numérique de ses mesures et constatations techniques.
    </>
  ),
  laadpalenDiscoverBlockText2: (
    <>
      <strong>2. Étape 2 : l’installation</strong>
      <br />
      Grâce à notre relevé, vous pouvez vous mettre rapidement au travail ! Vous
      pouvez estimer clairement le temps, la main-d’œuvre et le matériel dont
      vous avez besoin !
    </>
  ),
  laadpalenDiscoverBlockText3: (
    <>
      <strong>3. Étape 3 : notre assistance</strong>
      <br />
      Des opérations complexes ? Des tâches spécialisées avec lesquelles vous
      n’êtes pas entièrement à l'aise ? Une conception technique difficile ?
      <br />
      Vous pouvez compter sur AsBuilt... sur simple demande, nous envoyons un
      technicien chevronné chez votre client !
    </>
  ),
  laadpalenDiscoverBlockText4: (
    <>
      <strong>4. Étape 4 : l'attestation</strong>
      <br />
      Une fois un projet terminé, nous demandons un contrôle technique et en
      assurons le suivi !
    </>
  ),
  laadpalenDiscoverBlockText5: (
    <>
      <strong>
        5. Le contrôle se solde par un rejet ? Il y a un problème ? Des
        défectuosités ?
      </strong>
      <br />
      Nous nous rendons sur place <strong>et résolvons le problème !</strong>
    </>
  ),
  zonnepanelenTitle: "Installation de panneaux solaires",
  zonnepanelenSubtitle: "Mesures pour un processus d’installation impeccable",
  zonnepanelenBlockTitle1: "Évaluation technique",
  zonnepanelenBlockText1: (
    <>
      Installer des panneaux solaires n’est pas toujours simple. Un lieu n'est
      pas l'autre. Chaque mission nécessite donc une approche unique. Un relevé
      précis préalable des mesures du site rend l'installation beaucoup plus
      simple.
      <br />
      <br />
      Un mesurage correct du site vous permet d'éviter les mauvaises expériences
      pour votre client final. Votre installation peut être réalisée sans
      problème et vous ne rencontrerez pas de difficulté imprévue.
    </>
  ),
  zonnepanelenBlockTitle2: "Un gain de temps considérable.",
  zonnepanelenBlockText2: (
    <>
      Quelque chose ne va pas après l’installation ? Ou quelque chose n’a pas
      été approuvé lors de l’inspection de votre installation ? Pas de problème
      ! Ne perdez plus de temps à retourner chez les clients. Une pièce est
      défectueuse après l'installation de vos panneaux solaires ? AsBuilt vient
      la réparer.
    </>
  ),
  zonnepanelenBlockTitle3: "Une solution globale convaincante.",
  zonnepanelenBlockText3: (
    <>
      En étroite collaboration avec nos partenaires, nous proposons à nos
      clients une solution globale inédite. Qu'est-ce que cela signifie ?
      <br />
      <br />
      Nous veillons à ce que votre installation puisse être réalisée sans
      difficulté et soit prête à être certifiée.
      <br />
      <br />
      En cas de problème entraînant un refus lors du contrôle, nous revenons
      pour y remédier. Quoi qu'il arrive, chez nous, vous êtes entre de bonnes
      mains.
    </>
  ),
  zonnepanelenDiscoverTitle:
    "Vous trouverez ci-dessous un résumé, étape par étape, de la manière dont AsBuilt augmente votre efficacité !",
  zonnepanelenDiscoverBlockText1: (
    <>
      <strong>1. Étape 1 : le relevé des mesures</strong>
      <br />
      Vous vous connectez à notre plateforme numérique et confirmez la demande.
      <br />
      Un releveur spécialisé se rend TRÈS RAPIDEMENT sur place et vous remet un
      rapport numérique de ses mesures et constatations techniques, incluant des
      photos et des schémas.
    </>
  ),
  zonnepanelenDiscoverBlockText2: (
    <>
      <strong>2. Étape 2 : l’installation</strong>
      <br />
      Grâce à notre relevé, vous pouvez vous mettre rapidement au travail ! Vous
      pouvez estimer clairement le temps, la main-d’œuvre et le matériel dont
      vous avez besoin !
      <br />
      <strong>
        Cela ne manquera pas de laisser une impression positive à votre client
        !!
      </strong>
    </>
  ),
  zonnepanelenDiscoverBlockText3: (
    <>
      <strong>3. Étape 3 : notre assistance</strong>
      <br />
      Des opérations complexes ? Des tâches spécialisées avec lesquelles vous
      n’êtes pas entièrement à l'aise ? Une conception technique difficile ?
      Branchement et configuration du ou des convertisseurs ? Configuration du
      Wi-Fi ?
      <br />
      Vous pouvez compter sur AsBuilt... sur simple demande, nous envoyons un{" "}
      <u>technicien chevronné</u> chez votre client !
    </>
  ),
  zonnepanelenDiscoverBlockText4: (
    <>
      <strong>4. Étape 4 : l'attestation</strong>
      <br />
      Une fois un projet terminé, nous demandons un contrôle technique et en
      assurons le suivi !
    </>
  ),
  zonnepanelenDiscoverBlockText5: (
    <>
      <strong>
        5. Le contrôle se solde par un rejet ? Il y a un problème ? Des
        défectuosités ?
      </strong>
      <br />
      Nous nous rendons sur place <strong>et résolvons le problème !</strong>
      <br />
      <br />
      <strong>
        <u>Important:</u>
      </strong>{" "}
      vous pouvez également faire appel à nous pour remplacer des
      convertisseurs, pour assurer l’entretien d’un convertisseur, etc.
    </>
  ),
  aircoTitle: "Installation de climatiseurs",
  aircoSubtitle: "Mesures pour un processus d’installation impeccable",
  aircoBlockTitle1: "Évaluation technique",
  aircoBlockText1: (
    <>
      Les endroits où vous devez installer un climatiseur vous donnent parfois
      des sueurs froides ? Dans un monde idéal, installer un climatiseur devrait
      être aussi simple partout, mais ce n’est pas toujours le cas. Un relevé
      précis préalable des mesures du site vous permet de gagner beaucoup de
      temps et d'argent.
      <br />
      <br />
      En outre, un mesurage correct permet d'éviter les mauvaises expériences
      pour votre client final. En mesurant vos sites à l’avance, vous pouvez
      réaliser vos installations sans souci et éviter les difficultés imprévues.
    </>
  ),
  aircoBlockTitle2: "Un gain de temps considérable",
  aircoBlockText2: (
    <>
      Quelque chose ne va pas après l’installation ? Ou quelque chose n’a pas
      été approuvé lors de l’inspection de votre installation ? Pas de problème
      ! Ne perdez plus de temps à retourner chez les clients. Une pièce est
      défectueuse après l'installation de votre climatiseur ? AsBuilt vient la
      réparer.
    </>
  ),
  aircoBlockTitle3: "Une solution globale convaincante",
  aircoBlockText3: (
    <>
      Grâce à une étroite collaboration avec nos partenaires, nous vous
      proposons une solution globale inédite :
      <br />
      <ul>
        <li>
          Sur la base de notre rapport numérique, votre système peut être
          installé sans problème.
        </li>
        <li>
          Il y a tout de même un souci ? As Built résout tous les problèmes !
          Immédiatement !{" "}
          <strong>Après tout, vous êtes notre partenaire commercial !</strong>
        </li>
      </ul>
    </>
  ),
  aircoDiscoverTitle:
    "Découvrez ci-dessous, étape par étape, comment AsBuilt augmente votre efficacité !",
  aircoDiscoverBlockText1: (
    <>
      <strong>1. Étape 1 : le relevé des mesures</strong>
      <br />
      Vous vous connectez à notre plateforme numérique et confirmez la demande.
      <br />
      Un releveur spécialisé se rend TRÈS RAPIDEMENT sur place et vous remet un
      rapport numérique de ses mesures et constatations techniques.
    </>
  ),
  aircoDiscoverBlockText2: (
    <>
      <strong>2. Étape 2 : l’installation</strong>
      <br />
      Grâce à notre relevé, vous pouvez vous mettre rapidement au travail ! Vous
      pouvez estimer clairement le temps, la main-d’œuvre et le matériel dont
      vous avez besoin !
    </>
  ),
  aircoDiscoverBlockText3: (
    <>
      <strong>3. Étape 3 : notre assistance</strong>
      <br />
      Des opérations complexes ? Des tâches spécialisées avec lesquelles vous
      n’êtes pas entièrement à l'aise ? Une conception technique difficile ?
      <br />
      Vous pouvez compter sur AsBuilt... sur simple demande, nous envoyons un
      technicien chevronné chez votre client !
    </>
  ),
  fixesTitle: "Réparations",
  fixesBlockTitle1: "Service de réparation",
  fixesBlockText1:
    "Chez AsBuilt, nous visons une satisfaction client de 100 %. C’est pourquoi nous avons développé nos services de manière à ce que vous puissiez toujours compter sur nous. Il y a tout de même un problème ? Nous venons immédiatement le résoudre.",
  fixesBlockTitle2: "Nouveau contrôle",
  fixesBlockText2:
    "Si votre installation a été refusée, nous demandons automatiquement un nouveau contrôle dès que les problèmes ont été résolus.",
  linkLaadpalen: "bornes-de-recharge",
  linkZonnepanelen: "panneaux-solaires",
  linkAirco: "climatiseurs",
  linkContact: "contactez-nous",
  firstname: "PRENOM",
  company: "ENTREPRISE (SI PRIVE, NE REMPLISSEZ PAS)",
  productinterest: "Quels produits vous intéressent ?",
  remarks: "Vos commentaires",
  notallfieldsok: "Tous les données n'ont pas été remplis",
  questiontype: "Type de question",
  pricequote: "Demande de citation",
  otherquestion: "Autre question",
  sizing: "Des mesures",
  quotes: "Devis",
  interventions: "Interventions",
  jobsSubbarTitle: "VENEZ-VOUS RENFORCER L'ÉQUIPE AS BUILT ?",
  jobsSubbarText: (
    <>
      Dans notre organisation en pleine croissance, nous recherchons en
      permanence des personnes motivées collègues.
      <br />
      <br />
      Vous êtes intéressé par des bornes de recharge, des panneaux solaires ou
      des installations électriques ? en général? Sont à la fois la mesure des
      installations électriques et la La mise en œuvre est quelque chose pour
      vous ? Envie d'un nouveau challenge technique chez notre service
      après-vente ? Construire une carrière passionnante au sein d'une
      entreprise ambitieuse entreprise avec beaucoup d'attention pour votre
      développement personnel? Voudriez vous travailler dans une ambiance
      familiale avec un groupe de collègues sympas ?
      <br />
      <br />
      Alors un emploi chez As Built pourrait être quelque chose pour vous !
      <br />
      <br />
      Consultez nos offres d'emploi ouvertes ici :
    </>
  ),
  moreinfo: "Plus d'information",
  joboutro: (
    <>
      Donc, si vous recherchez un emploi au contenu varié dans un ambiance de
      travail dynamique et familiale alors vous êtes peut-être le profil que
      nous recherchons ? Postulez rapidement via{" "}
      <a href="mailto:hr@hertbelgium.be">hr@hertbelgium.be</a>
    </>
  ),
  vacature: "Poste vacant",
  backvacatures: "Retour à l'aperçu des postes vacants",
  jobresponsabilities: "Vos responsabilités",
  jobprofile: "Votre profil",
  joboffer: "Que proposons-nous ?",
};

export default translations;
