import nl_NL from "../i18n/nl.js";
import en from "../i18n/en.js";
import fr from "../i18n/fr.js";
import Translate from "../Translate/Translate";

const nlLocale = Translate.createLocale("nl", "Nederlands", "NL", nl_NL);
const enLocale = Translate.createLocale("en", "English", "EN", en);
const frLocale = Translate.createLocale("fr", "Français", "FR", fr);

const createLocales = () => {
  return [nlLocale, enLocale, frLocale];
};

export default {
  createLocales: () => createLocales(),
};
