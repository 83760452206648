import React from "react";
import ContactSection from "../components/ContactSection";
import TranslatedComponent from "./TranslatedComponent";
import Header from "../components/Header";
import BarHero from "../components/BarHero";
import SubBar from "../components/SubBar";
import JobsSection from "../components/JobsSection";
import Translate from "../Translate/Translate";
import JobsNL from "../data/JobsNL";
import JobsFR from "../data/JobsFR";

const data = {
  subbarTitle: "jobsSubbarTitle",
  subbarText: "jobsSubbarText",
};

const JobsPage = (props) => {
  const currentLocale = Translate.getCurrentLocale().i18n;
  let jobs;
  if (currentLocale === "nl") {
    jobs = JobsNL;
  }
  if (currentLocale === "fr") {
    jobs = JobsFR;
  }
  return (
    <div>
      <TranslatedComponent {...props}>
        <Header {...props} />
        <BarHero title="JOBS" />
        <SubBar title={data.subbarTitle} text={data.subbarText} />
        <JobsSection jobs={jobs} />
      </TranslatedComponent>
    </div>
  );
};

export default JobsPage;
