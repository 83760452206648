import React from "react";
import ZONNEPANELENITEM1 from "../assets/images/ASBUILT_SUN3.jpg";
import ZONNEPANELENITEM2 from "../assets/images/ASBUILT_SUN2.jpg";
import ZONNEPANELENITEM3 from "../assets/images/ASBUILT_SUN1.jpg";
import ZONNEPANELENICON from "../assets/images/ASBUILT_ZONNEPANELEN_ICON.svg";
import Discover from "../components/Discover";
import ContactSection from "../components/ContactSection";
import TranslatedComponent from "./TranslatedComponent";
import Header from "../components/Header";
import BarHero from "../components/BarHero";
import TechnicalHero from "../components/TechnicalHero";
import ContentItemNew from "../components/ContentItemNew";
import CarousselSection from "../components/CarousselSection";

const data = [
  {
    id: 1,
    title: "zonnepanelenBlockTitle1",
    desc: "zonnepanelenBlockText1",
    img: ZONNEPANELENITEM1,
    orientation: "right",
  },
  {
    id: 2,
    title: "zonnepanelenBlockTitle2",
    desc: "zonnepanelenBlockText2",
    img: ZONNEPANELENITEM2,
    orientation: "left",
  },
  {
    id: 3,
    title: "zonnepanelenBlockTitle3",
    desc: "zonnepanelenBlockText3",
    img: ZONNEPANELENITEM3,
    orientation: "right",
  },
];

const discoverTitle = "zonnepanelenDiscoverTitle";

const discoverData = [
  {
    id: 1,
    data: "zonnepanelenDiscoverBlockText1",
  },
  {
    id: 2,
    data: "zonnepanelenDiscoverBlockText2",
  },
  {
    id: 3,
    data: "zonnepanelenDiscoverBlockText3",
  },
  {
    id: 4,
    data: "zonnepanelenDiscoverBlockText4",
  },
  {
    id: 5,
    data: "zonnepanelenDiscoverBlockText5",
  },
];

const ZonnepanelenPage = (props) => {
  return (
    <div>
      <TranslatedComponent {...props}>
        <Header {...props} />
        <BarHero barIcon={ZONNEPANELENICON} />
        <TechnicalHero
          title="zonnepanelenTitle"
          text="zonnepanelenSubtitle"
          button="becomePartner"
          buttonLink="linkContact"
          specials={true}
        />
        <div className="container" style={{ marginTop: 60, marginBottom: 100 }}>
          {data.map((item) => (
            <ContentItemNew key={item.id} {...item} />
          ))}
        </div>
        <CarousselSection />
        <ContactSection />
      </TranslatedComponent>
    </div>
  );
};

export default ZonnepanelenPage;
