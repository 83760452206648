import React from "react";
import Translate from "../../Translate/Translate";

import ContactForm from "../ContactForm";
import Header from "../Header";
import "./style.scss";

const ContactSection = ({ page, ...otherProps }) => {
  return (
    <div>
      {page && <Header {...otherProps} />}
      <div
        className={`asbuilt-contact-section ${
          page ? "asbuilt-contact-section--page" : ""
        }`}
      >
        <div className="asbuilt-contact-section__container container">
          <div className="asbuilt-contact-section__left">
            <div
              className={`asbuilt-contact-section__left__toptitle ${
                !page
                  ? "asbuilt-contact-section__left__toptitle--section"
                  : null
              }`}
              data-aos="fade-up"
            >
              {Translate.translate("contactTop")}
            </div>
            <div
              className={`asbuilt-contact-section__left__title ${
                !page ? "asbuilt-contact-section__left__title--section" : null
              }`}
              data-aos="fade-up"
            >
              {Translate.translate("contact")}
            </div>
            <div
              className={`asbuilt-contact-section__left__desc ${
                !page ? "asbuilt-contact-section__left__desc--section" : null
              }`}
              data-aos="fade-up"
            >
              {Translate.translate("contactText")}
            </div>
          </div>
          <div className="asbuilt-contact-section__right" data-aos-delay="500">
            <ContactForm />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactSection;
