import React, { useState, useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import emailjs from "emailjs-com";
import ScaleLoader from "react-spinners/ScaleLoader";
import Translate from "../../Translate/Translate";

import { recaptchaKey, serviceID, templateID, userID } from "../../config.js";
import "./style.scss";

const ContactForm = () => {
  const name = useFormInput("");
  const firstname = useFormInput("");
  const company = useFormInput("");
  const kbo = useFormInput("");
  const email = useFormInput("");
  const telephone = useFormInput("");
  const message = useFormInput("");
  const [questionType, setQuestionType] = useState(undefined);
  const [selectedProducts, setSelectedProducts] = useState([]);

  const [captcha, setCaptcha] = useState(null);
  const recaptchaRef = useRef();

  const [sendingMail, setSendingMail] = useState(false);
  const [emailSent, setEmailSent] = useState(false);

  const acegProductList = [
    {
      name: Translate.translate("sizing"),
      value: "Opmetingen",
    },
    { name: Translate.translate("quotes"), value: "Offertes" },
    { name: Translate.translate("interventions"), value: "Interventies" },
  ];

  const changeSelectedProduct = (product) => {
    let existingProducts = [...selectedProducts];
    if (existingProducts.indexOf(product) > -1) {
      const productIndex = existingProducts.indexOf(product);
      existingProducts.splice(productIndex, 1);
      setSelectedProducts(existingProducts);
    } else {
      existingProducts.push(product);
      setSelectedProducts(existingProducts);
    }
  };

  function sendEmail(e) {
    e.preventDefault();
    if (!captcha) return;
    let theCompany =
      company && company.value && company.value.length ? company.value : "";
    let theKBO = kbo && kbo.value && kbo.value.length ? kbo.value : "";

    let templateParams = {
      from_name: name.value,
      first_name: firstname.value,
      last_name: name.value,
      company: theCompany,
      kbo: theKBO,
      question_type: questionType || "",
      product_interest: selectedProducts.join(", "),
      email: email.value,
      tel: telephone.value,
      question: message.value,
      reply_to: email.value,
      "g-recaptcah-response": captcha,
    };
    setSendingMail(true);

    /**
     * EMAILJS SEND
     */

    let isB2B = false;
    let b2bTemplate = "template_3iv1kpt";
    if (questionType === "prijsaanvraag" && theCompany.length) {
      isB2B = true;
    }

    emailjs
      .send(serviceID, isB2B ? b2bTemplate : templateID, templateParams, userID)
      .then(
        async (result) => {
          setSendingMail(false);
          setEmailSent(true);

          console.log("the contact mail was sent.");

          try {
            /**
             * STRATICS POST
             */

            console.log("sending data to stratics");

            const stratics_webcontact_token_live =
              "3C131445-236D-415A-852E-E89B78D84448";

            const stratics_webcontact_token_dev =
              "02C89263-B6EF-4CFE-99C0-8284B1785860";

            const currentURL = window.location.href;

            let interactionTokens = [
              {
                interactionToken: "WEB_CONTACT_ASBUILTBE",
                interactionDate: new Date().toISOString(),
                dumpData: `${
                  questionType === "prijsaanvraag"
                    ? "Prijsaanvraag"
                    : "Andere vraag"
                }: ${
                  message && message.value && message.value.length
                    ? message.value
                    : ""
                }`,
                url: currentURL,
              },
            ];

            if (questionType === "prijsaanvraag") {
              const suffix = currentURL.indexOf("?") > 0 ? "&" : "?";
              interactionTokens.push({
                interactionToken: "RFQ",
                interactionDate: new Date().toISOString(),
                dumpData: `Prijsaanvraag: ${
                  message && message.value && message.value.length
                    ? message.value
                    : ""
                }`,
                url: `${currentURL}${suffix}miptag=${selectedProducts.join(
                  ", ",
                )}`,
              });
            }

            const body = {
              firstName: firstname.value,
              familyName: name.value,
              consename: theCompany,
              consecode: theCompany && theCompany.length ? "COM" : "",
              vat: theKBO,
              genderCode: "",
              languageCode: Translate.getCurrentLocale()
                ? Translate.getCurrentLocale().i18n
                : "nl",
              email: email.value,
              phone: telephone.value,
              interactionTokens: interactionTokens,
            };

            /**
             * Fetch POST
             * Call _q endpoint for queue
             * Use stratics token in query params
             */
            console.log("using production env");
            const url = `https://api.mymip.be/RestAPI/AddContactData_q?token=${stratics_webcontact_token_live}`;
            const res = await fetch(url, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(body),
            });

            console.log("the url was fetched");

            const data = await res.json();

            console.log(data);

            if (
              !data ||
              !data.returnStatus ||
              data.returnStatus.errorNumber !== 0
            ) {
              console.log(data);
            } else {
              console.log("stratics succesfully sent");
            }
            /**
             * END STRATICS
             */
          } catch (e) {
            console.log("there was a problem with the stratics api");
            console.log(e);
          }
        },
        (error) => {
          console.log(error);
          setSendingMail(false);
        },
      );
  }

  function handleCaptcha(value) {
    setCaptcha(value);
  }

  return (
    <div>
      {emailSent && (
        <div className="bedankt asbuilt-contact-thanks" data-aos="fade-up">
          <h2>{Translate.translate("thanks")}</h2>
          <p>{Translate.translate("thanksText")}</p>
        </div>
      )}
      {!emailSent && (
        <form className="asbuilt-contact-form" onSubmit={sendEmail}>
          <input
            className="asbuilt-contact-form__item"
            name="name"
            type="text"
            placeholder={Translate.translate("name")}
            required
            {...name}
          />
          <input
            className="asbuilt-contact-form__item"
            name="firstname"
            type="text"
            placeholder={Translate.translate("firstname")}
            required
            {...firstname}
          />
          <input
            className="asbuilt-contact-form__item"
            name="email"
            type="email"
            placeholder={Translate.translate("email")}
            required
            {...email}
          />
          <input
            className="asbuilt-contact-form__item"
            name="telephone"
            type="tel"
            placeholder={Translate.translate("tel")}
            required
            {...telephone}
          />
          <input
            className="asbuilt-contact-form__item"
            name="company"
            type="text"
            placeholder={Translate.translate("company")}
            {...company}
          />
          {company && company.value && company.value.length > 2 && (
            <input
              className="asbuilt-contact-form__item"
              name="kbo"
              type="text"
              required
              placeholder="KBO"
              {...kbo}
            />
          )}
          <div style={{ marginTop: 20 }}>
            <div>
              <label>{Translate.translate("questiontype")}</label>
              <div
                style={{
                  width: "100%",
                  marginTop: 16,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <label
                  htmlFor="questiontype"
                  onClick={() => setQuestionType("prijsaanvraag")}
                >
                  <input
                    type="radio"
                    name="questiontype"
                    value="prijsaanvraag"
                    checked={
                      questionType === "prijsaanvraag" ? "checked" : null
                    }
                  />{" "}
                  {Translate.translate("pricequote")}
                </label>
                <label
                  htmlFor="questiontype"
                  style={{ marginTop: 10 }}
                  onClick={() => setQuestionType("andere")}
                >
                  <input
                    type="radio"
                    name="questiontype"
                    value="andere"
                    checked={questionType === "andere" ? "checked" : null}
                  />{" "}
                  {Translate.translate("otherquestion")}
                </label>
              </div>
            </div>
          </div>
          {questionType === "prijsaanvraag" && (
            <div style={{ marginTop: 20 }}>
              <div>
                <label>{Translate.translate("productinterest")}</label>
                <div
                  style={{
                    width: "100%",
                    marginTop: 16,
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  {acegProductList.map((product, p) => (
                    <div key={p}>
                      <label style={{ marginTop: 10 }}>
                        <input
                          type="checkbox"
                          name="productlist"
                          value={product.value}
                          checked={
                            selectedProducts.indexOf(product.value) > -1
                              ? "checked"
                              : null
                          }
                          onChange={() => changeSelectedProduct(product.value)}
                        />{" "}
                        {product.name}
                      </label>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
          <textarea
            className="asbuilt-contact-form__item asbuilt-contact-form__item--message"
            name="message"
            type="text"
            placeholder={Translate.translate("message")}
            required
            {...message}
            style={{ marginTop: 26 }}
          />
          <ReCAPTCHA
            className="asbuilt-contact-form__captcha"
            ref={recaptchaRef}
            onChange={handleCaptcha}
            sitekey={recaptchaKey}
          ></ReCAPTCHA>
          <button
            className="asbuilt-contact-form__submit"
            type="submit"
            disabled={sendingMail}
          >
            {sendingMail ? (
              <ScaleLoader size={45} color={"#ffffff"} loading={sendingMail} />
            ) : (
              <>{Translate.translate("contactUs")}</>
            )}
          </button>
        </form>
      )}
    </div>
  );
};

function useFormInput(initialValue) {
  const [value, setValue] = useState(initialValue);

  function handleChange(e) {
    setValue(e.target.value);
  }

  return {
    value,
    onChange: handleChange,
  };
}

export default ContactForm;
