const JobsFR = [
  {
    jobTitle: "Employé administratif technique",
    jobIntro: (
      <>
        En tant qu’employé administratif technique, vous avez un travail très
        varié. Vous serez responsable du suivi de nos projets, de la
        planification de certains techniciens et de la gestion de notre
        entrepôt. Vos connaissances en électricité sont indispensables pour
        pouvoir suivre avec précision les projets et préparer les bons matériaux
        dans l’entrepôt. Vous saisissez les données de ces projets sur notre
        plate-forme d’entreprise numérique et en assurez le suivi. Cette
        fonction variée et stimulante sera exercée depuis notre siège à
        Strombeek-Bever.
      </>
    ),
    responsabilities: (
      <>
        <ul>
          <li>
            Vous assurez le suivi, la gestion du magasin et la planification ;
          </li>
          <li>Vous cherchez à satisfaire vos clients ;</li>
          <li>Vous vous efforcez d’être précis(e). </li>
        </ul>
      </>
    ),
    profile: (
      <>
        <ul>
          <li>Connaissances techniques/électriques approfondies</li>
          <li>Sens de la communication</li>
          <li>Précision</li>
          <li>Esprit de service et orientation client</li>
          <li>Capacité à résoudre les problèmes</li>
          <li>Esprit d’équipe</li>
          <li>
            Connaissances linguistiques Néerlandais:{" "}
            <strong>très bonnes / Français: bonnes</strong>
          </li>
        </ul>
      </>
    ),
    offer: (
      <>
        <ul>
          <li>Environnement chaleureux et familial</li>
          <li>Voiture de société, GSM, ordinateur portable</li>
          <li>
            Organisation jeune et dynamique, tournée vers la technologie et le
            progrès
          </li>
          <li>Contrat de travail à temps plein et à durée indéterminée</li>
          <li>Chouettes collègues !</li>
        </ul>
      </>
    ),
    url: "employe-administratif",
  },
  {
    jobTitle: "Technicien deviseur",
    jobIntro: (
      <>
        En tant que technicien, vous partez le matin directement chez différents
        clients en Belgique. Vous vous rendez sur des sites où vous pouvez
        utiliser vos compétences techniques et votre savoir-faire pour rendre
        les installations techniques refusées conformes à la réglementation afin
        que notre client soit assuré d’un rapport d’inspection positif. Il
        s’agit principalement de bornes de recharge, d’onduleurs pour panneaux
        solaires, mais aussi de réalisations complètes d’électricité domestique.
        Votre perspicacité et votre précision sont importantes.
        <br />
        <br />
        Vous pourrez trouver les données des projets sur notre plateforme
        numérique d’entreprise et devrez les compléter. Vous passez toutes les
        deux semaines au bureau situé à Strombeek-Bever pour venir chercher le
        matériel nécessaire.
      </>
    ),
    responsabilities: (
      <>
        <ul>
          <li>
            Vous êtes responsable de la mise en conformité des installations
            électriques et vous essayez donc d’obtenir un rapport de contrôle
            positif ;
          </li>
          <li>Vous cherchez à satisfaire vos clients ;</li>
          <li>Vous vous efforcez d’être précis(e). </li>
        </ul>
      </>
    ),
    profile: (
      <>
        <ul>
          <li>Connaissances techniques/électriques approfondies</li>
          <li>Sens de la communication</li>
          <li>Précision</li>
          <li>Esprit de service et orientation client</li>
          <li>Capacité à résoudre les problèmes</li>
          <li>Esprit d’équipe</li>
          <li>
            Connaissances linguistiques Néerlandais:{" "}
            <strong>très bonnes / Français: bonnes</strong>
          </li>
        </ul>
      </>
    ),
    offer: (
      <>
        <ul>
          <li>Environnement chaleureux et familial</li>
          <li>Voiture de société, GSM, ordinateur portable</li>
          <li>
            Organisation jeune et dynamique, tournée vers la technologie et le
            progrès
          </li>
          <li>Contrat de travail à temps plein et à durée indéterminée</li>
          <li>Chouettes collègues !</li>
        </ul>
      </>
    ),
    url: "technicien-deviseur",
  },
  {
    jobTitle: "Métreur",
    jobIntro: (
      <>
        En tant que métreur, vous partez le matin directement chez différents
        clients en Wallonie. Vous vous rendez sur des sites où vous allez
        mesurer principalement des bornes de recharge, des panneaux solaires,
        mais aussi des réalisations complètes d’électricité domestique. Votre
        perspicacité et votre précision sont très importantes, il s’agit du
        travail préparatoire au processus d’installation.
        <br />
        <br />
        Vous devrez déterminer l’emplacement des onduleurs, des batteries, etc.
        que vos collègues placeront. Le tracé des câbles, la résistance de terre
        et la mesure du toit relèvent également de votre responsabilité. Vous
        traitez toutes ces données sur place dans la plateforme numérique
        d’entreprise.
      </>
    ),
    responsabilities: (
      <>
        <ul>
          <li>
            Vous assurez une préanalyse approfondie des installations
            électriques ;
          </li>
          <li>Vous cherchez à satisfaire vos clients ;</li>
        </ul>
      </>
    ),
    profile: (
      <>
        <ul>
          <li>Connaissances techniques/électriques approfondies</li>
          <li>Sens de la communication</li>
          <li>Précision</li>
          <li>Esprit de service et orientation client</li>
          <li>Capacité à résoudre les problèmes</li>
          <li>Esprit d’équipe</li>
          <li>
            Connaissances linguistiques Néerlandais:{" "}
            <strong>très bonnes / Français: bonnes</strong>
          </li>
        </ul>
      </>
    ),
    offer: (
      <>
        <ul>
          <li>Environnement chaleureux et familial</li>
          <li>Voiture de société, GSM, ordinateur portable</li>
          <li>
            Organisation jeune et dynamique, tournée vers la technologie et le
            progrès
          </li>
          <li>Contrat de travail à temps plein et à durée indéterminée</li>
          <li>Chouettes collègues !</li>
        </ul>
      </>
    ),
    url: "metreur",
  },
  {
    jobTitle: "Technicien du service de raccordement",
    jobIntro: (
      <>
        En tant que technicien, vous partez le matin directement chez différents
        clients en Belgique. Vous vous rendez sur des sites où vous allez
        connecter principalement des bornes de recharge, des onduleurs pour
        panneaux solaires, mais aussi des réalisations complètes d’électricité
        domestique. Votre perspicacité et votre précision sont primordiales.
        <br />
        <br />
        L’emplacement des onduleurs, batteries, etc. est déterminé par vos
        collègues. Les données exactes seront indiquées sur notre plateforme
        numérique d’entreprise. Vous passez toutes les deux semaines au bureau
        situé à Strombeek-Bever pour venir chercher le matériel nécessaire.
      </>
    ),
    responsabilities: (
      <>
        <ul>
          <li>
            Vous assurez le suivi, la gestion du magasin et la planification ;
          </li>
          <li>Vous cherchez à satisfaire vos clients ;</li>
          <li>Vous vous efforcez d’être précis(e). </li>
        </ul>
      </>
    ),
    profile: (
      <>
        <ul>
          <li>Connaissances techniques/électriques approfondies</li>
          <li>Sens de la communication</li>
          <li>Précision</li>
          <li>Esprit de service et orientation client</li>
          <li>Capacité à résoudre les problèmes</li>
          <li>Esprit d’équipe</li>
          <li>
            Connaissances linguistiques Néerlandais:{" "}
            <strong>très bonnes / Français: bonnes</strong>
          </li>
        </ul>
      </>
    ),
    offer: (
      <>
        <ul>
          <li>Environnement chaleureux et familial</li>
          <li>Voiture de société, GSM, ordinateur portable</li>
          <li>
            Organisation jeune et dynamique, tournée vers la technologie et le
            progrès
          </li>
          <li>Contrat de travail à temps plein et à durée indéterminée</li>
          <li>Chouettes collègues !</li>
        </ul>
      </>
    ),
    url: "technicien-du-service-de-raccordement",
  },
];

export default JobsFR;
