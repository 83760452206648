import { NavLink } from 'react-router-dom';
import './style.scss';
import Translate from '../../Translate/Translate';

const JobDetail = ({ job }) => {
  const currentLocale = Translate.getCurrentLocale().i18n;
  return (
    <div className="jobdetail-root">
      <div className="jobdetail-root__inner">
        <div className="jobdetail-root__inner__top">
          <p>{Translate.translate('vacature')}</p>
          <div>
            <NavLink to={`/${currentLocale}/jobs`}>
              {'<'} {Translate.translate('backvacatures')}
            </NavLink>
          </div>
        </div>
        <div className="jobdetail-root__inner__main">
          <h2>{job.jobTitle}</h2>
          <p>{job.jobIntro}</p>
          <h3>{Translate.translate('jobresponsabilities')}</h3>
          {job.responsabilities}
          <h3>{Translate.translate('jobprofile')}</h3>
          {job.profile}
          <h3>{Translate.translate('joboffer')}</h3>
          {job.offer}
          <p></p>
          <p>{Translate.translate('joboutro')}</p>
        </div>
      </div>
    </div>
  );
};

export default JobDetail;
