import "./style.scss";
import { NavLink } from "react-router-dom";
import Translate from "../../Translate/Translate";

const JobsSection = ({ jobs }) => {
  const currentLocale = Translate.getCurrentLocale().i18n;
  return (
    <div className="jobssection-root">
      <div className="jobssection-root__inner">
        {(jobs &&
          jobs.length &&
          jobs.map((job, j) => (
            <div key={j} className="jobssection-root__inner__job">
              <p className="vac">{Translate.translate("vacature")}</p>
              <h3>{job.jobTitle}</h3>
              <p>{job.jobIntro}</p>
              <NavLink to={`/${currentLocale}/jobs/${job.url}`}>
                {Translate.translate("moreinfo")} {">"}
              </NavLink>
            </div>
          ))) ||
          null}
      </div>
    </div>
  );
};

export default JobsSection;
