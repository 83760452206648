const translations = {
  homeTitle:
    "We helpen installateurs en aannemers bij het behalen van attesten en certificaten",
  homeDiscover: "ONTDEK HOE WE DIT DOEN",
  homePartner: "WORD PARTNER VAN ASBUILT",
  homeContact: "NEEM CONTACT OP",
  homeGoal: "Een doelgerichte oplossing voor installateurs en aannemers.",
  homeEfficiency: "VERHOOG UW EFFICIENTIE",
  homeTransparancy: "100% TRANSPARANT",
  homeCustomer: "KLANTGERICHT",
  homeTechnicalEvaluations: "TECHNISCHE EVALUATIES",
  homeFixes: "HERSTELLINGEN",
  linkTechnicalEvaluations: "technische-evaluaties",
  linkFixes: "herstellingen",
  carousselTitle1: "OPMETING EN INSPECTIE",
  carousselText1: (
    <>
      De AsBuilt experts gaan op locatie en stellen een gedetailleerd opmetings-
      en inspectieverslag op. Nadien krijgt u onze bevindingen en advies in een
      digitaal rapport met foto’s en schema’s.
    </>
  ),
  carousselTitle2: "INSTALLATIE",
  carousselText2: (
    <>
      U voert de installatie / het project uit op basis van het digitaal
      rapport.
    </>
  ),
  carousselTitle3: "ONDERSTEUNING",
  carousselText3: (
    <>
      Tijdens de installatie staan de AsBuilt experts altijd ter beschikking om
      complexe taken zoals aansluitingen, configuraties, …, uit te voeren.
    </>
  ),
  carousselTitle4: "KEURING",
  carousselText4: (
    <>Nadat de installatie is geplaatst, vragen we de keuring aan.</>
  ),
  carousselTitle5: "QUICK FIX",
  carousselText5: (
    <>
      Werd de installatie afgekeurd? Dan fiksen we de inbreuken en vragen we een
      herkeuring aan.
    </>
  ),
  homeGoalText: (
    <>
      Verhoog de efficiëntie van uw bedrijf. Win meer tijd. Houd uw klanten
      tevreden. De ambitie van AsBuilt is om het u zo eenvoudig mogelijk te
      maken en daarnaast uw klanten steeds tevreden te houden. Laat u ontzorgen
      bij de uitvoering van uw installaties en projecten.
    </>
  ),
  homeLaadpalen: "LAADPALEN",
  homeZonnepanelen: "ZONNEPANELEN",
  homeAirco: "AIRCO",
  homeBlockTitle1: "AsBuilt verhoogt de efficiëntie",
  homeBlockText1:
    "Voordat u bij een klant een installatie gaat plaatsen, komt AsBuilt ter plekke om alles correct op te meten. Hierdoor kan u sneller en gerichter te werk. Daarnaast wordt de kans dat er later iets misloopt, nihil.",
  homeBlockTitle2: "100% Transparant",
  homeBlockText2:
    "Door ons digitaal platform, heeft u steeds een overzicht van de huidige stand van zaken. Hierdoor kan u al uw projecten opvolgen, waar u maar bent en wanneer u maar wil. ",
  homeBlockTitle3: "Iets defect? Het wordt gefikst!",
  homeBlockText3: (
    <>
      Bij AsBuilt gaan we voor 100% klanttevredenheid. Ons dienstenaanbod werkt
      oplossingsgericht! Onvoorziene problemen? Defecten?{" "}
      <strong>Wij komen het meteen oplossen</strong>
    </>
  ),
  homeDiscoverTitle: "Ontdek hieronder de meerwaarde van AS BUILT…",
  homeDiscoverBlockText1: (
    <>
      <u>Stap 1: Opmeting en inspectie</u>
      <br />
      Onze gespecialiseerde mensen gaan bij uw klant langs en stellen een
      gedetailleerd opmetings- en inspectieverslag op. U krijgt onze bevindingen
      in een digitaal rapport, inclusief foto's en schema's!
    </>
  ),
  homeDiscoverBlockText2: (
    <>
      <u>Stap 2: Installatie</u>
      <br />U voert de installatie/het project uit op basis van ons digitaal
      rapport.
    </>
  ),
  homeDiscoverBlockText3: (
    <>
      <u>Stap 3: Onze hulp</u>
      <br />
      Complexe taken van het installatieproces (aansluitingen,
      configuraties,...) kunnen op uw verzoek door onze techniekers gebeuren.
      Dit geeft u meer tijd en ruimte om de focus te verleggen naar de voor u
      belangrijke aspecten van uw business.
    </>
  ),
  homeDiscoverBlockText4: (
    <>
      <u>Stap 4: Keuring</u>
      <br />
      Installatie klaar? Wij vragen de keuring aan!
    </>
  ),
  homeDiscoverBlockText5: (
    <>
      <u>Stap 5: Afgekeurd? Defecten?</u>
      <br />
      Problemen of afkeuringen? Defecten? AS Built gaat langs en lost op!
    </>
  ),
  contactTop: "WORD PARTNER VAN ASBUILT EN",
  contact: (
    <>
      ONTVANG UW EERSTE
      <br />
      OPMETING GRATIS
    </>
  ),
  contactText: (
    <>
      Contacteer ons vrijblijvend en dan meten we op hoe we u verder kunnen
      helpen.
    </>
  ),
  technicalTitle: "Technische Evaluaties",
  becomePartner: "WORD PARTNER VAN ASBUILT",
  thanks: "Bedankt",
  thanksText: "We beantwoorden zo snel mogelijk jouw vraag!",
  name: "NAAM",
  email: "E-MAIL",
  tel: "TELEFOON",
  message: "UW BERICHT",
  contactUs: "NEEM CONTACT OP",
  terms: "Algemene voorwaarden",
  address: "ADRES",
  laadpalenTitle: "Laadpalen installeren",
  laadpalenSubtitle: "Opmetingen voor een vlekkeloos installatieproces",
  efficiency: "VERHOOG DE EFFICIËNTIE",
  transparancy: "100% TRANSPARANTIE",
  customer: "GERICHT OP DE KLANT",
  laadpalenBlockTitle1: "Technische evaluatie",
  laadpalenBlockText1: (
    <>
      In een ideale wereld zou het overal even eenvoudig zijn om uw laadpalen te
      plaatsen, maar dat is niet altijd het geval. Voordat u de laadpalen kan
      plaatsen, is een nauwkeurige opmeting van de locatie een enorme troef.
      <br />
      <br />
      Door het correct opmeten van de locatie vermijdt u slechte ervaringen voor
      de eindklant. Uw installatie kan vlekkeloos geïnstalleerd worden en u
      ervaart geen onvoorziene moeilijkheden.
    </>
  ),
  laadpalenBlockTitle2: "Enorme tijdwinst",
  laadpalenBlockText2: (
    <>
      Na de installatie iets niet in orde? Of is er iets niet goedgekeurd
      tijdens de inspectie van uw installatie? Verlies geen tijd meer met terug
      te gaan naar klanten. Gaat er na de plaatsing van uw laadpaal, een
      onderdeel defect? Dan komt AsBuilt het fixen.
    </>
  ),
  laadpalenBlockTitle3: "Een ongeziene totaaloplossing",
  laadpalenBlockText3: (
    <>
      Door een nauwe samenwerking met onze partners, bieden we onze klanten een
      ongeziene totaaloplossing aan. Wat willen we hier zeggen?
      <br />
      <br />
      We zorgen ervoor dat uw installatie vlekkeloos geplaatst kan worden en
      klaar is om gecertificeerd te worden.
      <br />
      <br />
      In het geval er iets misloopt waardoor u niet door de keuring geraakt,
      komen wij terug om deze pijnpunten aan te pakken. Wat er ook gebeurd, bij
      ons bent u in goede handen.
    </>
  ),
  laadpalenDiscoverTitle:
    "Bekijk hieronder hoe AS Built uw efficiënte verhoogt!",
  laadpalenDiscoverBlockText1: (
    <>
      <strong>1. Stap 1: de opmeting</strong>
      <br />
      U logt in op ons digitaal platform en bevestigt de aanvraag.
      <br />
      Een gespecialiseerde opmeter gaat ZEER SNEL langs en bezorgt u een
      digitaal verslag van zijn technische opmetingen en bevindingen.
    </>
  ),
  laadpalenDiscoverBlockText2: (
    <>
      <strong>2. Stap 2: de installatie</strong>
      <br />
      Op basis van onze opmeting kan u snel aan de slag! U kan duidelijk
      inschatten hoeveel tijd, mensen en materiaal u nodig heeft!
    </>
  ),
  laadpalenDiscoverBlockText3: (
    <>
      <strong>3. Stap 3: onze hulp</strong>
      <br />
      Complexe handelingen? Specialistenwerk waar u minder thuis in bent? Een
      moeilijke technische bevalling?
      <br />U kan rekenen op AS BUILT ...op uw eenvoudig verzoek sturen wij een
      toptechnieker naar uw klant!
    </>
  ),
  laadpalenDiscoverBlockText4: (
    <>
      <strong>4. Stap 4: attest</strong>
      <br />
      Eens een project afgerond is, vragen wij een technische keuring aan en
      volgen op!
    </>
  ),
  laadpalenDiscoverBlockText5: (
    <>
      <strong>5. Afgekeurd? Probleem? Defect?</strong>
      <br />
      Wij gaan ter plaatse <strong>en lossen op!</strong>
    </>
  ),
  zonnepanelenTitle: "Zonnepanelen installeren",
  zonnepanelenSubtitle: "Opmetingen voor een vlekkeloos installatieproces",
  zonnepanelenBlockTitle1: "Technische evaluatie",
  zonnepanelenBlockText1: (
    <>
      Zonnepanelen installeren is niet altijd even eenvoudig. De ene locatie is
      de andere niet. Dus elke opdracht heeft een unieke aanpak nodig. Een
      nauwkeurige opmeting van de locatie voordat u gaat plaatsen, maakt het
      installatieproces veel eenvoudiger.
      <br />
      <br />
      Door het correct opmeten van de locatie vermijdt u slechte ervaringen voor
      de eindklant. Uw installatie kan vlekkeloos geïnstalleerd worden en u
      ervaart geen onvoorziene moeilijkheden.
    </>
  ),
  zonnepanelenBlockTitle2: "Enorme tijdwinst.",
  zonnepanelenBlockText2: (
    <>
      Na de installatie iets niet in orde? Of is er iets niet goedgekeurd
      tijdens de inspectie van uw installatie? Geen probleem! Verlies geen tijd
      meer met terug te gaan naar klanten. Gaat er na de plaatsing van uw
      zonnepanelen, een onderdeel defect? Dan komt AsBuilt het fixen.
    </>
  ),
  zonnepanelenBlockTitle3: "Een overtuigende totaaloplossing.",
  zonnepanelenBlockText3: (
    <>
      Door een nauwe samenwerking met onze partners, bieden we onze klanten een
      ongeziene totaaloplossing aan. Wat willen we hier met zeggen?
      <br />
      <br />
      We zorgen ervoor dat uw installatie vlekkeloos geplaatst kan worden en
      klaar is om gecertificeerd te worden.
      <br />
      <br />
      In het geval er iets misloopt waardoor u niet door de keuring geraakt,
      komen wij terug om deze pijnpunten aan te pakken. Wat er ook gebeurd, bij
      ons bent u in goede handen.
    </>
  ),
  zonnepanelenDiscoverTitle:
    "Hieronder samengevat, stap per stap, hoe AS Built uw efficiënte verhoogt!",
  zonnepanelenDiscoverBlockText1: (
    <>
      <strong>1. Stap 1: de opmeting</strong>
      <br />
      U logt in op ons digitaal platform en bevestigt de aanvraag.
      <br />
      Een gespecialiseerde opmeter gaat ZEER SNEL langs en bezorgt u een
      digitaal verslag van zijn technische opmetingen en bevindingen, voorzien
      van foto's en schema's.
    </>
  ),
  zonnepanelenDiscoverBlockText2: (
    <>
      <strong>2. Stap 2: de installatie</strong>
      <br />
      Op basis van onze opmeting kan u snel aan de slag! U kan duidelijk
      inschatten hoeveel tijd, mensen en materiaal u nodig heeft!
      <br />
      <strong>Dit maakt absoluut indruk bij uw klant!</strong>
    </>
  ),
  zonnepanelenDiscoverBlockText3: (
    <>
      <strong>3. Stap 3: onze hulp</strong>
      <br />
      Complexe handelingen? Specialistenwerk waar u minder thuis in bent? Een
      moeilijke technische bevalling? Aansluiten en configureren van de
      omvormer(s)? Astellen van de Wi-Fi?
      <br />U kan rekenen op AS BUILT ...op uw eenvoudig verzoek sturen wij een{" "}
      <u>toptechnieker</u> naar uw klant!
    </>
  ),
  zonnepanelenDiscoverBlockText4: (
    <>
      <strong>4. Stap 4: attest</strong>
      <br />
      Eens een project afgerond is, vragen wij een technische keuring aan en
      volgen op!
    </>
  ),
  zonnepanelenDiscoverBlockText5: (
    <>
      <strong>5. Afgekeurd? Probleem? Defect?</strong>
      <br />
      Wij gaan ter plaatse <strong>en lossen op!</strong>
      <br />
      <br />
      <strong>
        <u>Belangrijk:</u>
      </strong>{" "}
      u kan ook een beroep doen op ons om defecte omvormers te vervangen, of om
      het onderhoud van een omvormer te verzorgen, of.....
    </>
  ),
  aircoTitle: "Airco's installeren",
  aircoSubtitle: "Opmetingen voor een vlekkeloos installatieproces",
  aircoBlockTitle1: "Technische evaluatie",
  aircoBlockText1: (
    <>
      Krijgt u het soms ook warm of koud van de verschillende locaties waar u
      airco’s moet plaatsen? In een ideale wereld zou het overal even eenvoudig
      zijn om deze te plaatsen, maar dat is niet altijd het geval. Een
      nauwkeurige opmeting voordat u gaat plaatsen kan u heel wat tijd en geld
      besparen.
      <br />
      <br />
      Daarnaast vermijdt u slechte ervaringen voor de eindklant door het correct
      opmeten van de locatie. Door uw sites op voorhand te laten opmeten kan u
      zorgeloos uw installaties plaatsen en vermijdt u onvoorziene tegenslagen.
    </>
  ),
  aircoBlockTitle2: "Enorme tijdwinst",
  aircoBlockText2: (
    <>
      Na de installatie iets niet in orde? Of is er iets niet goedgekeurd
      tijdens de inspectie van uw installatie? Geen probleem! Verlies geen tijd
      meer met terug te gaan naar klanten. Gaat er na de plaatsing van uw airco
      een onderdeel defect? Dan komt AsBuilt het fixen.
    </>
  ),
  aircoBlockTitle3: "Een overtuigende totaaloplossing",
  aircoBlockText3: (
    <>
      Dankzij een nauwe samenwerking met onze partners, bieden wij u een
      ongeziene totaaloplossing aan:
      <br />
      <ul>
        <li>
          Uw installatie kan, op basis van ons digitaal rapport, probleemloos
          geplaatst worden.
        </li>
        <li>
          Loopt er toch wat mis? As Built neemt alle pijnpunten weg!
          Onmiddellijk! <strong>U bent immers onze businesspartner!</strong>
        </li>
      </ul>
    </>
  ),
  aircoDiscoverTitle:
    "Hieronder leest u, stap voor stap,  hoe AS Built uw efficiënte verhoogt!",
  aircoDiscoverBlockText1: (
    <>
      <strong>1. Stap 1: de opmeting</strong>
      <br />
      U logt in op ons digitaal platform en bevestigt de aanvraag.
      <br />
      Een gespecialiseerde opmeter gaat ZEER SNEL langs en bezorgt u een
      digitaal verslag van zijn technische opmetingen en bevindingen.
    </>
  ),
  aircoDiscoverBlockText2: (
    <>
      <strong>2. Stap 2: de installatie</strong>
      <br />
      Op basis van onze opmeting kan u snel aan de slag! U kan duidelijk
      inschatten hoeveel tijd, mensen en materiaal u nodig heeft!
    </>
  ),
  aircoDiscoverBlockText3: (
    <>
      <strong>3. Stap 3: onze hulp</strong>
      <br />
      Complexe handelingen? Specialistenwerk waar u minder thuis in bent? Een
      moeilijke technische bevalling?
      <br />U kan rekenen op AS BUILT ...op uw eenvoudig verzoek sturen wij een
      toptechnieker naar uw klant!
    </>
  ),
  fixesTitle: "Herstellingen",
  fixesBlockTitle1: "Hersteldienst",
  fixesBlockText1:
    "Bij AsBuilt gaan we voor de 100% klanttevredenheid. Daarom hebben we onze services zodanig ontwikkeld zodat u steeds op ons kan rekenen. Loopt er iets mis? Dan komen we het meteen oplossen.",
  fixesBlockTitle2: "Herkeuring",
  fixesBlockText2:
    "In het geval dat uw installatie werd afgekeurd, vragen wij automatisch een nieuwe keuring aan van zodra de inbreuken werden rechtgezet.",
  linkLaadpalen: "laadpalen",
  linkZonnepanelen: "zonnepanelen",
  linkAirco: "airco",
  linkContact: "contacteer-ons",
  firstname: "VOORNAAM",
  company: "BEDRIJF (INDIEN PARTICULIER NIET INVULLEN)",
  productinterest: "In welke producten heeft u interesse?",
  remarks: "Uw opmerkingen",
  notallfieldsok: "Niet alle velden werden ingevuld",
  questiontype: "Type vraag",
  pricequote: "Prijsaanvraag",
  otherquestion: "Andere vraag",
  sizing: "Opmetingen",
  quotes: "Offertes",
  interventions: "Interventies",
  jobsSubbarTitle: "KOM JIJ HET AS BUILT TEAM VERSTERKEN?",
  jobsSubbarText: (
    <>
      In onze groeiende organisatie zijn we continu op zoek naar gemotiveerde
      collega’s.
      <br />
      <br />
      Heb je interesse in laadpalen, zonnepanelen of elektrische installaties in
      het algemeen? Zijn zowel het opmeten van elektrische installaties als de
      uitvoering ervan iets voor jou? Zin in een nieuwe technische uitdaging bij
      onze servicedienst? Een boeiende loopbaan opbouwen binnen een ambitieus
      bedrijf met veel aandacht voor je persoonlijke ontwikkeling? Wil je graag
      werken in een familiale sfeer met een groep toffe collega's?
      <br />
      <br />
      Dan is een job bij As Built misschien iets voor jou!
      <br />
      <br />
      Bekijk hier onze openstaande jobaanbiedingen:
    </>
  ),
  moreinfo: "Meer informatie",
  joboutro: (
    <>
      Dus als je op zoek bent naar een job met afwisselende inhoud in een
      dynamische en familiale werksfeer dan ben jij misschien wel het profiel
      dat we zoeken? Solliciteer snel via{" "}
      <a href="mailto:hr@hertbelgium.be">hr@hertbelgium.be</a>
    </>
  ),
  vacature: "Vacature",
  backvacatures: "Terug naar vacature overzicht",
  jobresponsabilities: "Jouw verantwoordelijkheiden",
  jobprofile: "Jouw profiel",
  joboffer: "Wat bieden wij?",
};

export default translations;
