const JobsNL = [
  {
    jobTitle: "Technieker offertedienst",
    jobIntro: (
      <>
        Als technieker vertrek je ’s morgens rechtstreeks naar verschillende
        klanten in België. Je bezoekt locaties waarbij je je technische skills
        en know-how kan inzetten om afgekeurde technische installaties conform
        de regelgeving te maken zodat onze klant zeker is van een positief
        keuringsverslag. Het gaat voornamelijk om laadpalen, omvormers voor
        zonnepanelen maar ook volledige projecten in huishoudelijke
        elektriciteit. Je inzicht en nauwkeurigheid is belangrijk.
        <br />
        <br />
        De gegevens van de projecten kan je terugvinden in ons digitaal
        bedrijfsplatform en dien je aan te vullen. Je komt 2-wekelijks langs het
        kantoor gelegen te Strombeek-Bever om het nodige materiaal op te halen.
      </>
    ),
    responsabilities: (
      <>
        <ul>
          <li>
            Je staat in voor het conform maken elektrische installaties en
            streeft zo naar een positief keuringsverslag;
          </li>
          <li>Je streeft naar klantentevredenheid;</li>
          <li>Je streeft naar een positief keuringsverslag.</li>
        </ul>
      </>
    ),
    profile: (
      <>
        <ul>
          <li>Grondige technische/elektrische kennis</li>
          <li>Communicatief</li>
          <li>Nauwkeurig</li>
          <li>Service minded en klantgericht</li>
          <li>Probleemoplossend vermogen</li>
          <li>Teamplayer</li>
          <li>
            Talenkennis Nederlands:{" "}
            <strong>Zeer goed / Frans: gemiddeld</strong>
          </li>
        </ul>
      </>
    ),
    offer: (
      <>
        <ul>
          <li>Warme en familiale omgeving</li>
          <li>Bedrijfswagen, gsm, laptop</li>
          <li>
            Jong en dynamische organisatie, met oog voor technologie en
            vooruitgang
          </li>
          <li>Voltijds bediendecontract van onbepaalde duur</li>
          <li>Fijne collega's!</li>
        </ul>
      </>
    ),
    url: "technieker-offertedienst",
  },
  {
    jobTitle: "Technisch administratief bediende",
    jobIntro: (
      <>
        Als technisch administratief bediende heb je een heel variërende job. Je
        zal instaan voor de opvolging van onze projecten, de planning van enkele
        techniekers en het beheer van ons magazijn. Je elektrische kennis is
        belangrijk zodat je de projecten nauwkeurig kan opvolgen en zodat je de
        juiste materialen kan klaarzetten in het magazijn.
      </>
    ),
    responsabilities: (
      <>
        <ul>
          <li>Je staat in voor de opvolging, magazijnbeheer en planning;</li>
          <li>Je streeft naar klantentevredenheid;</li>
          <li>Je streeft naar nauwkeurigheid.</li>
        </ul>
      </>
    ),
    profile: (
      <>
        <ul>
          <li>Grondige technische/elektrische kennis</li>
          <li>Communicatief</li>
          <li>Nauwkeurig</li>
          <li>Service minded en klantgericht</li>
          <li>Probleemoplossend vermogen</li>
          <li>Teamplayer</li>
          <li>
            Talenkennis Nederlands: <strong>Zeer goed / Frans: goed</strong>
          </li>
        </ul>
      </>
    ),
    offer: (
      <>
        <ul>
          <li>Warme en familiale omgeving</li>
          <li>Bedrijfswagen, gsm, laptop</li>
          <li>
            Jong en dynamische organisatie, met oog voor technologie en
            vooruitgang
          </li>
          <li>Voltijds bediendecontract van onbepaalde duur</li>
          <li>Fijne collega's!</li>
        </ul>
      </>
    ),
    url: "technisch-administratief-bediende",
  },
  {
    jobTitle: "Opmeter",
    jobIntro: (
      <>
        Als opmeter vertrek je ’s morgens rechtstreeks naar verschillende
        klanten in Wallonië. Je bezoekt locaties waar je voornamelijk laadpalen,
        zonnepanelen maar ook volledige projecten in huishoudelijke
        elektriciteit gaat opmeten. Je inzicht en nauwkeurigheid is hier van
        groot belang, dit is het voorbereidend werk van het installatieproces.
        <br />
        <br />
        Je zal de plaats van de omvormers, batterijen e.d. moeten bepalen die je
        collega’s zullen plaatsen. Het uittekenen van kabeltrajecten, de
        aardingsweerstand, en het opmeten van het dak behoort ook tot je
        verantwoordelijkheid. Al deze gegevens verwerk je ter plaatse in het
        digitale bedrijfsplatform.
      </>
    ),
    responsabilities: (
      <>
        <ul>
          <li>
            - Je staat in voor een grondige vooranalyse van de elektrische
            installaties;
          </li>
          <li>Je streeft naar klantentevredenheid;</li>
        </ul>
      </>
    ),
    profile: (
      <>
        <ul>
          <li>Grondige technische/elektrische kennis</li>
          <li>Communicatief</li>
          <li>Nauwkeurig</li>
          <li>Service minded en klantgericht</li>
          <li>Probleemoplossend vermogen</li>
          <li>Teamplayer</li>
          <li>
            Talenkennis Nederlands:{" "}
            <strong>Zeer goed / Frans: gemiddeld</strong>
          </li>
        </ul>
      </>
    ),
    offer: (
      <>
        <ul>
          <li>Warme en familiale omgeving</li>
          <li>Bedrijfswagen, gsm, laptop</li>
          <li>
            Jong en dynamische organisatie, met oog voor technologie en
            vooruitgang
          </li>
          <li>Voltijds bediendecontract van onbepaalde duur</li>
          <li>Fijne collega's!</li>
        </ul>
      </>
    ),
    url: "opmeter",
  },
  {
    jobTitle: "Technieker aansluitdienst",
    jobIntro: (
      <>
        Als technieker vertrek je ’s morgens rechtstreeks naar verschillende
        klanten in België. Je bezoekt locaties waar je voornamelijk laadpalen,
        omvormers voor zonnepanelen maar ook volledige projecten in
        huishoudelijke elektriciteit zal gaan aansluiten. Je inzicht en
        nauwkeurigheid is van groot belang.
        <br />
        <br />
        De plaats van de omvormers, batterijen e.d zijn bepaald door je
        collega’s. De exacte gegevens zullen aangegeven staan in ons digitaal
        bedrijfsplatform. Je komt 2- wekelijks langs het kantoor gelegen te
        Strombeek Bever om het nodige materiaal op te halen.
      </>
    ),
    responsabilities: (
      <>
        <ul>
          <li>
            Je staat in voor de aansluiting van de elektrische installaties;
          </li>
          <li>Je streeft naar klantentevredenheid;</li>
          <li>Je streeft naar een positief keuringsverslag.</li>
        </ul>
      </>
    ),
    profile: (
      <>
        <ul>
          <li>Grondige technische/elektrische kennis</li>
          <li>Communicatief</li>
          <li>Nauwkeurig</li>
          <li>Service minded en klantgericht</li>
          <li>Probleemoplossend vermogen</li>
          <li>Teamplayer</li>
          <li>
            Talenkennis Nederlands:{" "}
            <strong>Zeer goed / Frans: gemiddeld</strong>
          </li>
        </ul>
      </>
    ),
    offer: (
      <>
        <ul>
          <li>Warme en familiale omgeving</li>
          <li>Bedrijfswagen, gsm, laptop</li>
          <li>
            Jong en dynamische organisatie, met oog voor technologie en
            vooruitgang
          </li>
          <li>Voltijds bediendecontract van onbepaalde duur</li>
          <li>Fijne collega's!</li>
        </ul>
      </>
    ),
    url: "technieker-aansluitdienst",
  },
];

export default JobsNL;
