import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import Translate from "../../Translate/Translate";

import "./style.scss";
import LOGO from "../../assets/images/ASBUILT_LOGO_HEADER.svg";
import GLOBE from "../../assets/images/globe.svg";

const Header = (props) => {
  const [openNavBar, setOpenNavBar] = useState(false);
  const [openLanguage, setOpenLanguage] = useState(false);
  const currentLocale = Translate.getCurrentLocale().i18n;

  function toggleSideBar() {
    setOpenNavBar((prev) => !prev);
  }

  function toggleLanguage(e) {
    e.preventDefault();
    setOpenLanguage((prev) => !prev);
  }

  function changeLanguage(e, i18n) {
    e.preventDefault();
    setOpenNavBar((prev) => !prev);
    if (i18n !== Translate.getCurrentLocale().i18n) {
      const newURL = props.location.pathname.replace(
        Translate.getCurrentLocale().i18n,
        i18n,
      );
      Translate.setLocale(i18n);
      props.history.push(newURL);
    }
  }

  return (
    <div className="asbuilt-header container">
      {openLanguage && (
        <div
          className="openLanguageToggle"
          onClick={(e) => toggleLanguage(e)}
        ></div>
      )}
      <NavLink className="asbuilt-header__logo" to={`/${currentLocale}`}>
        <img src={LOGO} alt="Header Logo" />
      </NavLink>
      <div className="asbuilt-header__menu">
        <NavLink
          to={`/${currentLocale}/${Translate.translate(
            "linkTechnicalEvaluations",
          )}`}
          className="asbuilt-header__menu__item"
          activeClassName="asbuilt-header__menu__item--active"
        >
          {Translate.translate("homeTechnicalEvaluations")}
        </NavLink>
        <NavLink
          to={`/${currentLocale}/${Translate.translate("linkFixes")}`}
          className="asbuilt-header__menu__item"
          activeClassName="asbuilt-header__menu__item--active"
        >
          {Translate.translate("homeFixes")}
        </NavLink>
        {(currentLocale === "nl" || currentLocale === "fr") && (
          <NavLink
            to={`/${currentLocale}/jobs`}
            className="asbuilt-header__menu__item"
            activeClassName="asbuilt-header__menu__item--active"
          >
            JOBS
          </NavLink>
        )}
        <NavLink
          className="asbuilt-header__menu__contact"
          to={`/${currentLocale}/${Translate.translate("linkContact")}`}
        >
          CONTACT
        </NavLink>
        <div className={`asbuilt-header__language`}>
          <button
            className={`asbuilt-header__language__icon`}
            onClick={(e) => toggleLanguage(e)}
          >
            <img src={GLOBE} alt="language" width="100%" />
          </button>
          <div className={`asbuilt-header__language__box`}>
            <div
              className={`asbuilt-header__language__box__inner asbuilt-header__language__box__langselect ${
                openLanguage ? "open" : ""
              }`}
            >
              <ul>
                <li>
                  <button
                    onClick={(e) => changeLanguage(e, "nl")}
                    className={`asbuilt-header__language__box__inner__button`}
                    disabled={Translate.getCurrentLocale().i18n === "nl"}
                  >
                    NL
                  </button>
                </li>
                <li>
                  <button
                    onClick={(e) => changeLanguage(e, "en")}
                    className={`asbuilt-header__language__box__inner__button`}
                    disabled={Translate.getCurrentLocale().i18n === "en"}
                  >
                    EN
                  </button>
                </li>
                <li>
                  <button
                    onClick={(e) => changeLanguage(e, "fr")}
                    className={`asbuilt-header__language__box__inner__button`}
                    disabled={Translate.getCurrentLocale().i18n === "fr"}
                  >
                    FR
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`asbuilt-header__hamburger-icon ${
          openNavBar ? "asbuilt-header__hamburger-icon--open" : null
        }`}
        onClick={toggleSideBar}
      >
        <span />
        <span />
        <span />
      </div>
      <div
        className={`asbuilt-header__hamburger-menu ${
          openNavBar ? "asbuilt-header__hamburger-menu--open" : null
        }`}
      >
        <NavLink
          className="asbuilt-header__hamburger-menu__logo"
          onClick={toggleSideBar}
          to={`/${currentLocale}`}
        >
          <img src={LOGO} alt="Header Logo" />
        </NavLink>
        <NavLink
          className="asbuilt-header__hamburger-menu__item"
          activeClassName="asbuilt-header__hamburger-menu__item--active"
          onClick={toggleSideBar}
          to={`/${currentLocale}/${Translate.translate(
            "linkTechnicalEvaluations",
          )}`}
        >
          {Translate.translate("homeTechnicalEvaluations")}
        </NavLink>
        <NavLink
          className="asbuilt-header__hamburger-menu__item"
          activeClassName="asbuilt-header__hamburger-menu__item--active"
          onClick={toggleSideBar}
          to={`/${currentLocale}/${Translate.translate("linkFixes")}`}
        >
          {Translate.translate("homeFixes")}
        </NavLink>
        <NavLink
          className="asbuilt-header__hamburger-menu__item"
          activeClassName="asbuilt-header__hamburger-menu__item--active"
          onClick={toggleSideBar}
          to={`/${currentLocale}/${Translate.translate("linkContact")}`}
        >
          CONTACT
        </NavLink>
        <div
          className={`asbuilt-header__hamburger-icon asbuilt-header__hamburger-icon--mobile ${
            openNavBar ? "asbuilt-header__hamburger-icon--open" : null
          }`}
          onClick={toggleSideBar}
        >
          <span />
          <span />
          <span />
        </div>
      </div>
      <div
        className={`asbuilt-header__hamburger-overlay ${
          openNavBar ? "asbuilt-header__hamburger-overlay--open" : null
        }`}
        onClick={toggleSideBar}
      ></div>
    </div>
  );
};

export default Header;
