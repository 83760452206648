import React from "react";
import HomePageHero from "../components/HomePageHero";
import ContactSection from "../components/ContactSection";
import TranslatedComponent from "./TranslatedComponent";
import Header from "../components/Header";
import CarousselSection from "../components/CarousselSection";

const HomePage = (props) => {
  return (
    <div>
      <TranslatedComponent {...props}>
        <Header {...props} />
        <HomePageHero />
        <CarousselSection />
        <ContactSection />
      </TranslatedComponent>
    </div>
  );
};

export default HomePage;
