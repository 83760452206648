const translations = {
  homeTitle: "We help installers and contractors obtain their certificates",
  homeDiscover: "DISCOVER HOW WE DO THIS",
  homePartner: "BECOME A PARTNER OF ASBUILT",
  homeContact: "CONTACT US",
  homeGoal: "A targeted solution for installers and contractors.",
  homeEfficiency: "INCREASE YOUR EFFICIENCY",
  homeTransparancy: "100% TRANSPARENT",
  homeCustomer: "CUSTOMER-ORIENTED",
  homeTechnicalEvaluations: "TECHNICAL EVALUATIONS",
  homeFixes: "REPAIRS",
  linkTechnicalEvaluations: "technical-evaluations",
  linkFixes: "repairs",
  carousselTitle1: "SURVEY AND INSPECTION",
  carousselText1: (
    <>
      The AsBuilt experts visit your site and draw up a detailed survey and
      inspection report. Afterwards, you receive our findings and
      recommendations in a digital report, including photos and diagrams.
    </>
  ),
  carousselTitle2: "INSTALLATION",
  carousselText2: (
    <>You carry out the installation/project based on the digital report.</>
  ),
  carousselTitle3: "SUPPORT",
  carousselText3: (
    <>
      The AsBuilt experts are available throughout the installation process to
      perform complex tasks such as connections, configurations, etc.
    </>
  ),
  carousselTitle4: "INSPECTION",
  carousselText4: (
    <>After the installation has been completed, we apply for an inspection.</>
  ),
  carousselTitle5: "QUICK FIX",
  carousselText5: (
    <>
      Your installation didn’t pass the inspection? Then we will fix the
      problems and apply for a re-inspection.
    </>
  ),
  homeGoalText: (
    <>
      Increase your company’s efficiency. Save time. Keep your customers
      satisfied. The objective of AsBuilt is to make life as easy as possible
      for you and keep your customers satisfied at all times. Let us unburden
      you with your installations and projects.
    </>
  ),
  homeLaadpalen: "CHARGING STATIONS",
  homeZonnepanelen: "SOLAR PANELS",
  homeAirco: "AIR CONDITIONING",
  homeBlockTitle1: "AsBuilt increases efficiency",
  homeBlockText1:
    "Before you install anything on your customer’s premises, AsBuilt visits the site to take accurate measurements. This allows you to work more quickly and efficiently. In addition, any chances of something going wrong later will be reduced to zero.",
  homeBlockTitle2: "100% transparent",
  homeBlockText2:
    "Thanks to our digital platform, you always have an overview of the current state of affairs. This allows you to monitor all your projects, wherever you are and whenever you want. ",
  homeBlockTitle3: "Something not working? It will be fixed!",
  homeBlockText3: (
    <>
      At AsBuilt, we aim for 100% customer satisfaction. Our range of services
      is geared towards solutions! Unforeseen problems? Faults?{" "}
      <strong>We’ll come over and sort things out right away</strong>
    </>
  ),
  homeDiscoverTitle: "Discover the added value of ASBUILT below...",
  homeDiscoverBlockText1: (
    <>
      <u>Step 1: Survey and inspection</u>
      <br />
      Our specialist staff visit your customer’s premises and draw up a detailed
      measurement and inspection report. You receive our findings in a digital
      report, including photos and diagrams!
    </>
  ),
  homeDiscoverBlockText2: (
    <>
      <u>Step 2: Installation</u>
      <br />
      You carry out the installation/project based on our digital report.
    </>
  ),
  homeDiscoverBlockText3: (
    <>
      <u>Step 3: Our help</u>
      <br />
      Complex tasks in the installation process (connections, configurations,
      etc.) can be carried out by our technicians at your request. This gives
      you more time and space to focus on those aspects of your business that
      are important to you.
    </>
  ),
  homeDiscoverBlockText4: (
    <>
      <u>Step 4: Inspection</u>
      <br />
      Installation ready? We will request an inspection!
    </>
  ),
  homeDiscoverBlockText5: (
    <>
      <u>Step 5: Didn’t pass the inspection? Faults?</u>
      <br />
      Problems or rejections? Faults? AsBuilt will come over and sort things
      out!
    </>
  ),
  contactTop: "BECOME A PARTNER OF ASBUILT AND",
  contact: (
    <>
      RECEIVE YOUR FIRST
      <br />
      SURVEY FREE OF CHARGE
    </>
  ),
  contactText: (
    <>
      Contact us, free of obligation, and we will assess how we can help you
      further.
    </>
  ),
  technicalTitle: "Technical Evaluations",
  becomePartner: "BECOME A PARTNER OF ASBUILT",
  thanks: "Thank you",
  thanksText: "We will answer your question as soon as possible!",
  name: "NAME",
  email: "EMAIL",
  tel: "TELEPHONE",
  message: "YOUR MESSAGE",
  contactUs: "CONTACT US",
  terms: "Terms and Conditions",
  address: "ADDRESS",
  laadpalenTitle: "Installing charging stations",
  laadpalenSubtitle: "Surveys for a flawless installation process",
  efficiency: "INCREASE EFFICIENCY",
  transparancy: "100% TRANSPARENCY",
  customer: "CUSTOMER ORIENTATION",
  laadpalenBlockTitle1: "Technical evaluation",
  laadpalenBlockText1: (
    <>
      In an ideal world, charging stations could be installed just as easily
      wherever you want, but that’s not always the case. An accurate survey of
      the site prior to the installation of a charging station is a huge asset.
      <br />
      <br />
      Correct measurements of the location avoid any negative experiences for
      the end customer. Your system can be flawlessly installed and you will not
      experience any unforeseen difficulties.
    </>
  ),
  laadpalenBlockTitle2: "Huge time savings",
  laadpalenBlockText2: (
    <>
      Something not in order after installation? Or did something not be
      approved during the inspection of your installation? Don’t lose time any
      longer by having to return to customers. Once your charging station is
      installed, has a part become defective? AsBuilt will come by to fix it.
    </>
  ),
  laadpalenBlockTitle3: "An unprecedented total solution",
  laadpalenBlockText3: (
    <>
      Thanks to close cooperation with our partners, we offer our customers an
      unprecedented total solution. What do we want to say here?
      <br />
      <br />
      We ensure that your system can be flawlessly installed and is ready to be
      certified.
      <br />
      <br />
      In the event that something goes wrong and your project doesn’t pass the
      inspection, we come back to address these issues. Whatever happens, you’re
      in good hands with AsBuilt.
    </>
  ),
  laadpalenDiscoverTitle: "See below how AsBuilt increases your efficiency!",
  laadpalenDiscoverBlockText1: (
    <>
      <strong>1. Step 1: the survey</strong>
      <br />
      You log in to our digital platform and confirm the request.
      <br />A specialised surveyor visits VERY QUICKLY and provides you with a
      digital report of his or her technical measurements and findings.
    </>
  ),
  laadpalenDiscoverBlockText2: (
    <>
      <strong>2. Step 2: the installation</strong>
      <br />
      Based on our survey, you can get started quickly! You can clearly estimate
      the amount of time, people and materials you need!
    </>
  ),
  laadpalenDiscoverBlockText3: (
    <>
      <strong>3. Step 3: our help</strong>
      <br />
      Complex operations? Specialist work you’re not so familiar with? A
      difficult technical delivery?
      <br />
      You can count on ASBUILT... we will send a top technician to your customer
      at your request!
    </>
  ),
  laadpalenDiscoverBlockText4: (
    <>
      <strong>4. Step 4: certificate</strong>
      <br />
      Once a project has been completed, we request a technical inspection and
      follow it up!
    </>
  ),
  laadpalenDiscoverBlockText5: (
    <>
      <strong>
        5. Didn’t pass the inspection? Is there a problem? Or a fault?
      </strong>
      <br />
      We visit the site <strong>and sort things out!</strong>
    </>
  ),
  zonnepanelenTitle: "Installing solar panels",
  zonnepanelenSubtitle: "Surveys for a flawless installation process",
  zonnepanelenBlockTitle1: "Technical evaluation",
  zonnepanelenBlockText1: (
    <>
      Installing solar panels is not always equally easy. Not all locations are
      the same. So every assignment needs a unique approach. An accurate
      measurement of the location before starting the installation will make the
      installation process much easier.
      <br />
      <br />
      Correct measurements of the location avoid any negative experiences for
      the end customer. Your system can be flawlessly installed and you will not
      experience any unforeseen difficulties.
    </>
  ),
  zonnepanelenBlockTitle2: "Huge time savings.",
  zonnepanelenBlockText2: (
    <>
      Something not in order after installation? Or did something not be
      approved during the inspection of your installation? No problem! Don’t
      lose time any longer by having to return to customers. Once your solar
      panels are installed, has a part become defective? AsBuilt will come by to
      fix it.
    </>
  ),
  zonnepanelenBlockTitle3: "A convincing total solution.",
  zonnepanelenBlockText3: (
    <>
      Thanks to close cooperation with our partners, we offer our customers an
      unprecedented total solution. What do we want to say here?
      <br />
      <br />
      We ensure that your system can be flawlessly installed and is ready to be
      certified.
      <br />
      <br />
      In the event that something goes wrong and your project doesn’t pass the
      inspection, we come back to address these issues. Whatever happens, you’re
      in good hands with AsBuilt.
    </>
  ),
  zonnepanelenDiscoverTitle:
    "Below is a step-by-step summary of how AsBuilt increases your efficiency!",
  zonnepanelenDiscoverBlockText1: (
    <>
      <strong>1. Step 1: the survey</strong>
      <br />
      You log in to our digital platform and confirm the request.
      <br />A specialised surveyor visits VERY QUICKLY and provides you with a
      digital report of his or her technical surveys and findings, illustrated
      by photographs and diagrams.
    </>
  ),
  zonnepanelenDiscoverBlockText2: (
    <>
      <strong>2. Step 2: the installation</strong>
      <br />
      Based on our survey, you can get started quickly! You can clearly estimate
      the amount of time, people and materials you need!
      <br />
      <strong>This will definitely impress your customer!</strong>
    </>
  ),
  zonnepanelenDiscoverBlockText3: (
    <>
      <strong>3. Step 3: our help</strong>
      <br />
      Complex operations? Specialist work you’re not so familiar with? A
      difficult technical delivery? Connection and configuration of the
      converter(s)? Adjustment of the Wi-Fi?
      <br />
      You can count on ASBUILT... we will send a <u>top technician</u> to your
      customer at your request!
    </>
  ),
  zonnepanelenDiscoverBlockText4: (
    <>
      <strong>4. Step 4: certificate</strong>
      <br />
      Once a project has been completed, we request a technical inspection and
      follow it up!
    </>
  ),
  zonnepanelenDiscoverBlockText5: (
    <>
      <strong>
        5. Didn’t pass the inspection? Is there a problem? Or a fault?
      </strong>
      <br />
      We visit the site <strong>and sort things out!</strong>
      <br />
      <br />
      <strong>
        <u>Important:</u>
      </strong>{" "}
      you can also call on us to replace defective converters, or to take care
      of the maintenance of a converter, or anything else that needs to be done.
    </>
  ),
  aircoTitle: "Installing air conditioning systems",
  aircoSubtitle: "Surveys for a flawless installation process",
  aircoBlockTitle1: "Technical evaluation",
  aircoBlockText1: (
    <>
      Do any of the locations where you are asked to install air conditioning
      systems leave you hot or cold? In an ideal world, air conditioning systems
      could be installed just as easily wherever you want, but that’s not always
      the case. An accurate measurement of the location before starting the
      installation can save you a lot of time and money.
      <br />
      <br />
      In addition, correctly measuring the installation site avoids any negative
      experiences for the end customer. By having locations measured in advance,
      you can enjoy a hassle-free installation process and avoid unforeseen
      setbacks.
    </>
  ),
  aircoBlockTitle2: "Huge time savings",
  aircoBlockText2: (
    <>
      Something not in order after installation? Or did something not be
      approved during the inspection of your installation? No problem! Don’t
      lose time any longer by having to return to customers. Once your air
      conditioning system is installed, has a part become defective? AsBuilt
      will come by to fix it.
    </>
  ),
  aircoBlockTitle3: "A convincing total solution",
  aircoBlockText3: (
    <>
      Thanks to close cooperation with our partners, we offer you an
      unprecedented total solution:
      <br />
      <ul>
        <li>
          Based on our digital report, your system can be installed without any
          problems.
        </li>
        <li>
          Is something nevertheless going wrong? AsBuilt will make it right!
          Immediately! <strong>After all, you’re our business partner!</strong>
        </li>
      </ul>
    </>
  ),
  aircoDiscoverTitle:
    "Below you can read, step by step, how AsBuilt increases your efficiency!",
  aircoDiscoverBlockText1: (
    <>
      <strong>1. Step 1: the survey</strong>
      <br />
      You log in to our digital platform and confirm the request.
      <br />A specialised surveyor visits VERY QUICKLY and provides you with a
      digital report of his or her technical measurements and findings.
    </>
  ),
  aircoDiscoverBlockText2: (
    <>
      <strong>2. Step 2: the installation</strong>
      <br />
      Based on our survey, you can get started quickly! You can clearly estimate
      the amount of time, people and materials you need!
    </>
  ),
  aircoDiscoverBlockText3: (
    <>
      <strong>3. Step 3: our help</strong>
      <br />
      Complex operations? Specialist work you’re not so familiar with? A
      difficult technical delivery?
      <br />
      You can count on ASBUILT... we will send a top technician to your customer
      at your request!
    </>
  ),
  fixesTitle: "Repairs ",
  fixesBlockTitle1: "Repair service",
  fixesBlockText1:
    "At AsBuilt, we aim for 100% customer satisfaction. This is why we have developed our services in such a way that you can always count on us. Is something going wrong? We’ll come over and sort things out right away.",
  fixesBlockTitle2: "Re-inspection",
  fixesBlockText2:
    "If the system you installed failed to be approved, we automatically request a new inspection as soon as the problems have been resolved.",
  linkLaadpalen: "charging-stations",
  linkZonnepanelen: "solar-panels",
  linkAirco: "air-conditioning",
  linkContact: "contact-us",
  firstname: "FIRSTNAME",
  company: "COMPANY (IF PRIVATE DO NOT FILL IN)",
  productinterest: "Which products are you interested in?",
  remarks: "Your remarks",
  notallfieldsok: "Not all fields are filled in",
  questiontype: "Question type",
  pricequote: "Price quotation",
  otherquestion: "Other question",
  sizing: "Measurements",
  quotes: "Quotations",
  interventions: "Interventions",
};

export default translations;
